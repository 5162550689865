import React from 'react'

export const Close = ({ color, size, cursor, ...props }) => (
  <svg width={size} height={size} cursor={cursor} viewBox='0 0 24 24' {...props}>
    <path
      fill={color}
      d='M11.1,10l8.666-8.666a.781.781,0,0,0-1.1-1.1L10,8.9,1.333.23a.781.781,0,0,0-1.1,1.1L8.895,10,.229,18.667a.781.781,0,0,0,1.1,1.1L10,11.106l8.666,8.666a.781.781,0,0,0,1.1-1.1Z'
      transform='translate(0 -0.001)'
    />
  </svg>
)

Close.defaultProps = {
  color: '#FF7708',
  size: 24,
  cursor: 'pointer'
}
