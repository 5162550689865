import React from 'react'

export const HappyFaceEmoji = ({ size, primaryColor, secondaryColor }) => (
  <svg width={size} height={size} viewBox='0 0 52.994 52.994'>
    <circle
      fill={primaryColor}
      cx='18.736'
      cy='18.736'
      r='18.736'
      transform='translate(0 26.497) rotate(-45)'
    />
    <g transform='translate(18.557 19.19)'>
      <path
        fill={secondaryColor}
        d='M628.571-196.076a36.462,36.462,0,0,1-13.95.021.785.785,0,0,0-.882,1.1.98.98,0,0,0,.05.085c2.047,3.119,4.813,4.837,7.789,4.838h0c2.976,0,5.744-1.718,7.792-4.837a.947.947,0,0,0,.091-.176A.784.784,0,0,0,628.571-196.076Z'
        transform='translate(-613.656 204.553)'
      />
      <g transform='translate(0.27 0)'>
        <g transform='translate(0)'>
          <path
            fill={secondaryColor}
            d='M619.848-247.245a2.428,2.428,0,0,1-2.239,2.581,2.428,2.428,0,0,1-2.239-2.581,2.428,2.428,0,0,1,2.239-2.581A2.429,2.429,0,0,1,619.848-247.245Z'
            transform='translate(-615.37 249.826)'
          />
          <g transform='translate(10.806)'>
            <path
              fill={secondaryColor}
              d='M688.45-247.245a2.428,2.428,0,0,1-2.239,2.581,2.428,2.428,0,0,1-2.239-2.581,2.428,2.428,0,0,1,2.239-2.581A2.429,2.429,0,0,1,688.45-247.245Z'
              transform='translate(-683.972 249.826)'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

HappyFaceEmoji.defaultProps = {
  primaryColor: '#f8f8f8',
  secondaryColor: '#dcdedf',
  size: 53
}
