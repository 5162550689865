export const Logout = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox='0 0 24.005 22.5'>
    <g transform='translate(-255 -475.75)'>
      <path
        stroke={color}
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5px'
        d='M262.505,487h15.75'
      />
      <path
        stroke={color}
        strokeLinecap='round'
        fill='none'
        strokeLinejoin='round'
        strokeWidth='1.5px'
        d='M274.505,490.754l3.75-3.75-3.75-3.75'
      />
      <path
        stroke={color}
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5px'
        d='M270.754,491.5V496a1.437,1.437,0,0,1-1.364,1.5H257.117a1.438,1.438,0,0,1-1.363-1.5V478a1.437,1.437,0,0,1,1.363-1.5H269.39a1.437,1.437,0,0,1,1.364,1.5v4.5'
      />
    </g>
  </svg>
)

Logout.defaultProps = {
  color: '#bdc3c7',
  width: 24,
  height: 22
}
