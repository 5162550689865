import React from 'react'

export const Share = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 21 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.7571 14.2934C15.4971 14.2934 14.3691 14.8682 13.6207 15.7692L8.75205 13.005C8.90345 12.5789 8.98667 12.1208 8.98667 11.6434C8.98667 11.1661 8.90345 10.708 8.75205 10.282L13.6203 7.51707C14.3686 8.41835 15.4968 8.99333 16.7569 8.99333C19.0045 8.99333 20.8332 7.16406 20.8332 4.91548C20.8333 2.66757 19.0047 0.838867 16.7571 0.838867C14.5092 0.838867 12.6804 2.66757 12.6804 4.91539C12.6804 5.39279 12.7635 5.85099 12.915 6.27714L8.0465 9.04212C7.29814 8.14132 6.17024 7.56672 4.91053 7.56672C2.66234 7.56672 0.833252 9.39542 0.833252 11.6433C0.833252 13.8911 2.66234 15.7198 4.91053 15.7198C6.17024 15.7198 7.29805 15.1452 8.0464 14.2445L12.915 17.0089C12.7635 17.435 12.6803 17.8933 12.6803 18.3708C12.6803 20.6186 14.5091 22.4473 16.757 22.4473C19.0046 22.4473 20.8333 20.6185 20.8333 18.3708C20.8333 16.1226 19.0047 14.2934 16.7571 14.2934ZM16.7571 2.26539C18.2182 2.26539 19.4068 3.45415 19.4068 4.91539C19.4068 6.37738 18.2182 7.56672 16.7571 7.56672C15.2958 7.56672 14.1069 6.37738 14.1069 4.91539C14.1069 3.45415 15.2958 2.26539 16.7571 2.26539ZM4.91063 14.2934C3.44901 14.2934 2.25987 13.1046 2.25987 11.6434C2.25987 10.1821 3.44901 8.99333 4.91063 8.99333C6.37167 8.99333 7.56024 10.1821 7.56024 11.6434C7.56024 13.1046 6.37157 14.2934 4.91063 14.2934ZM16.7571 21.0209C15.2958 21.0209 14.1069 19.832 14.1069 18.3709C14.1069 16.9092 15.2958 15.7199 16.7571 15.7199C18.2182 15.7199 19.4068 16.9092 19.4068 18.3709C19.4068 19.832 18.2182 21.0209 16.7571 21.0209Z'
      fill={color}
    />
  </svg>
)

Share.defaultProps = {
  size: '21px',
  color: '#313A4D'
}
