import React from 'react'

export const RegularFaceEmoji = ({ size, primaryColor, secondaryColor }) => (
  <svg width={size} height={size} viewBox='0 0 52.994 52.994'>
    <g transform='translate(0 0)'>
      <circle
        fill={primaryColor}
        cx='18.736'
        cy='18.736'
        r='18.736'
        transform='translate(0 26.497) rotate(-45)'
      />
      <g transform='matrix(0.995, -0.105, 0.105, 0.995, 18.136, 20.659)'>
        <g transform='translate(0 0)'>
          <g transform='translate(0 0)'>
            <ellipse fill={secondaryColor} cx='2.239' cy='2.581' rx='2.239' ry='2.581' />
          </g>
          <g transform='translate(10.806 0)'>
            <ellipse fill={secondaryColor} cx='2.239' cy='2.581' rx='2.239' ry='2.581' />
          </g>
        </g>
        <g transform='translate(0.27 8.935)'>
          <g transform='translate(0 0)'>
            <path
              fill={secondaryColor}
              d='M7.372,4.529a10.008,10.008,0,0,1-7.1-2.936A.933.933,0,0,1,1.593.274a8.183,8.183,0,0,0,11.559,0,.933.933,0,1,1,1.319,1.319A10.009,10.009,0,0,1,7.372,4.529Z'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

RegularFaceEmoji.defaultProps = {
  primaryColor: '#f8f8f8',
  secondaryColor: '#dcdedf',
  size: 53
}
