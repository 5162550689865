import React from 'react'
import styled from 'styled-components'

export const SmileComputer = ({ width, height }) => (
  <svg width={width} height={height} viewBox='0 0 40.779 30.438'>
    <Path
      d='M12.926 14.261a9.6 9.6 0 0012.926 0M15.512 6.463v2.585M23.268 6.463v2.585'
      transform='translate(1 1)'
    ></Path>
    <Path
      d='M3.878 16.805V2.585A2.586 2.586 0 016.463 0h25.853a2.586 2.586 0 012.585 2.585v14.219M0 21.975a6.463 6.463 0 006.463 6.463h25.853a6.463 6.463 0 006.463-6.463z'
      transform='translate(1 1)'
    ></Path>
  </svg>
)

SmileComputer.defaultProps = {
  width: '40px',
  height: '30px'
}

const Path = styled.path`
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
`
