export const Information = ({ width, height, bgColor, iconColor }) => (
  <svg width={width} height={height} viewBox='0 0 20 20'>
    <circle cx='10' cy='10' r='10' fill={bgColor}></circle>
    <path
      fill={iconColor}
      d='M2.527 11.538V5.213a1.267 1.267 0 00-2.527 0v6.325a1.267 1.267 0 002.527 0zM1.263 0A1.22 1.22 0 00-.001 1.171a1.267 1.267 0 002.527 0A1.22 1.22 0 001.263 0z'
      transform='translate(9 4)'
    ></path>
  </svg>
)

Information.defaultProps = {
  width: '20px',
  height: '20px',
  bgColor: '#294671',
  iconColor: 'white'
}
