import React from 'react'

export const Clock = ({ size, color }) => (
  <svg width={size} height={size} viewBox='0 0 16 16'>
    <path
      d='M2.343,13.656A7.95,7.95,0,0,1,0,8q0-.034,0-.068V7.921A8,8,0,0,1,7.861,0H8a8,8,0,0,1,8,8c0,.023,0,.045,0,.068v.011A8,8,0,0,1,8.138,16H8A7.949,7.949,0,0,1,2.343,13.656ZM8,14.75h.121l.11,0h0a6.765,6.765,0,0,0,1.3-.173h0l.04-.01.022-.005.008,0a6.772,6.772,0,0,0,3.953-2.738.63.63,0,0,1,.041-.072A6.721,6.721,0,0,0,14.75,8.069V8.056c0-.019,0-.037,0-.056A6.758,6.758,0,0,0,8,1.25H7.881l-.112,0h0a6.778,6.778,0,0,0-1.026.114h0l-.053.01h0l-.047.009-.03.006L6.6,1.4a6.766,6.766,0,0,0-4.16,2.785.63.63,0,0,1-.041.071A6.726,6.726,0,0,0,1.25,7.932v.011c0,.019,0,.038,0,.057A6.758,6.758,0,0,0,8,14.75Z'
      fill={color}
    />
    <g transform='translate(4.9 2.375)'>
      <path
        d='M159.9,76a.625.625,0,0,0-.625.625v4.741l-2.292,2.292a.625.625,0,1,0,.884.884l2.475-2.475a.625.625,0,0,0,.183-.442v-5A.625.625,0,0,0,159.9,76Z'
        transform='translate(-156.804 -76)'
        fill={color}
      />
    </g>
  </svg>
)

Clock.defaultProps = {
  color: '#7f8c8d',
  size: 16
}
