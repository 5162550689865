import React from 'react'

export const Facebook = ({ size, color }) => (
  <svg width={size} height={size} viewBox='0 0 14.686 14.597'>
    <path
      fill={color}
      d='M14.686,7.343A7.343,7.343,0,1,0,6.2,14.6V9.466H4.331V7.343H6.2V5.725A2.591,2.591,0,0,1,8.969,2.868a11.292,11.292,0,0,1,1.644.143V4.819H9.687a1.061,1.061,0,0,0-1.2,1.147V7.343h2.037L10.2,9.466H8.491V14.6A7.345,7.345,0,0,0,14.686,7.343Z'
    />
  </svg>
)

Facebook.defaultProps = {
  color: '#7F8C8D',
  size: 20
}
