import React from 'react'

export const Camera = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 16 11.062'>
      <path
        fill={color}
        d='M16 8V3.1a1.875 1.875 0 00-3-1.5l-.016.012-1.918 1.652V2.5a2.5 2.5 0 00-2.5-2.5H2.5A2.5 2.5 0 000 2.5v6.062a2.5 2.5 0 002.5 2.5h6.062a2.5 2.5 0 002.5-2.5v-.731l1.9 1.638L13 9.5A1.875 1.875 0 0016 8a.625.625 0 00-1.25 0 .625.625 0 01-.987.509l-2.7-2.325V4.912l2.7-2.325a.625.625 0 01.987.509V8c0 .343 1.25.343 1.25 0zm-6.187.565a1.251 1.251 0 01-1.25 1.25H2.5a1.251 1.251 0 01-1.25-1.25V2.5A1.251 1.251 0 012.5 1.25h6.062a1.251 1.251 0 011.25 1.25z'
      ></path>
    </svg>
  )
}

Camera.defaultProps = {
  color: '#212121',
  size: 18
}
