import React from 'react'

export const PasswordEyeLock = ({ error, ...props }) => {
  const color = error ? '#db0000' : '#bdc3c7'
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24.214'
      height='20.121'
      viewBox='0 0 24.214 20.121'
      {...props}
    >
      <g transform='translate(0.857 1.06)'>
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M401.788,3360l18.75-18'
          transform='translate(-399.757 -3342)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M407.969,3358.051a9.984,9.984,0,0,0,3.036.449c4.1.069,8.258-2.813,10.824-5.637a1.663,1.663,0,0,0,0-2.226,20.588,20.588,0,0,0-3.074-2.757'
          transform='translate(-399.757 -3342)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M413.418,3345.283a9.567,9.567,0,0,0-2.413-.282c-4.031-.067-8.2,2.752-10.821,5.635a1.663,1.663,0,0,0,0,2.226,20.786,20.786,0,0,0,2.6,2.4'
          transform='translate(-399.757 -3342)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M407.255,3351.75a3.749,3.749,0,0,1,3.75-3.75'
          transform='translate(-399.757 -3342)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M414.755,3351.75h0a3.75,3.75,0,0,1-3.75,3.751'
          transform='translate(-399.757 -3342)'
        />
      </g>
    </svg>
  )
}
