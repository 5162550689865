import React from 'react'

export const MenuUser = ({ color, size, stroke, ...rest }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height='auto'
    viewBox='0 0 19.55 19.55'
    {...rest}
  >
    <g transform='translate(-466.1 -2122.1)'>
      <g transform='translate(466.75 2122.75)'>
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={stroke}
          d='M477.008,2135.017a4.258,4.258,0,1,0-4.258-4.258A4.259,4.259,0,0,0,477.008,2135.017Z'
          transform='translate(-467.883 -2123.458)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={stroke}
          d='M482.3,2142.333a7.905,7.905,0,0,0-11.214,0'
          transform='translate(-467.569 -2126.008)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={stroke}
          d='M475.875,2141a9.125,9.125,0,1,0-9.125-9.125A9.125,9.125,0,0,0,475.875,2141Z'
          transform='translate(-466.75 -2122.75)'
        />
      </g>
    </g>
  </svg>
)

MenuUser.defaultProps = {
  color: '#212121',
  size: '18px',
  stroke: '1.3px'
}
