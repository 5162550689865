export const CheckedIcon = ({ color, size }) => {
  return (
    <svg width={size} height={size} fill='none' viewBox={'0 0 16 16'}>
      <path
        fill={color}
        d='M12.736 3.97a.732.732 0 011.047 0c.286.29.29.756.01 1.05L7.88 12.01a.734.734 0 01-1.065.02L3.217 8.384a.757.757 0 01.24-1.223.733.733 0 01.807.163l3.052 3.093 5.4-6.425a.244.244 0 01.02-.022z'
      />
    </svg>
  )
}

CheckedIcon.defaultProps = {
  color: '#3C3C3C',
  size: 20
}
