import React from 'react'

export const VittudeLogo = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox='0 0 61.63 18.486'>
    <g transform='translate(14.539 3.416)'>
      <path
        fill={color}
        d='M148.109,78q.715.426.715.908a1.7,1.7,0,0,1-.275.757L145.7,84.545a1.262,1.262,0,0,1-.44.42,1.149,1.149,0,0,1-.6.185A1.164,1.164,0,0,1,144.1,85a1.11,1.11,0,0,1-.378-.3l-.124-.151-2.86-4.9a1.547,1.547,0,0,1-.276-.743q0-.494.716-.921a1.823,1.823,0,0,1,.824-.3.622.622,0,0,1,.476.186,2.6,2.6,0,0,1,.364.558l1.8,3.465,1.8-3.465c.1-.175.176-.3.227-.379a.89.89,0,0,1,.24-.233.751.751,0,0,1,.441-.117A1.644,1.644,0,0,1,148.109,78Z'
        transform='translate(-140.462 -74.449)'
      />
      <path
        fill={color}
        d='M153.631,75.855a.584.584,0,0,1-.365.282,2.987,2.987,0,0,1-1.39,0,.575.575,0,0,1-.364-.289,1.152,1.152,0,0,1-.125-.364,5.633,5.633,0,0,1,0-.949,1.153,1.153,0,0,1,.117-.351q.192-.371,1.073-.371c.578,0,.926.124,1.046.371a1.113,1.113,0,0,1,.13.364,5.408,5.408,0,0,1,0,.95A1.108,1.108,0,0,1,153.631,75.855Zm-2.262,2.552a3.948,3.948,0,0,1,.02-.475,1.317,1.317,0,0,1,.117-.365q.192-.357,1.073-.358a1.459,1.459,0,0,1,.936.234.757.757,0,0,1,.247.591c.009.092.014.225.014.4v4.814a4.067,4.067,0,0,1-.021.475,1.1,1.1,0,0,1-.13.365q-.181.357-1.06.357c-.577,0-.931-.124-1.058-.371a1.318,1.318,0,0,1-.117-.358,3.9,3.9,0,0,1-.02-.481Z'
        transform='translate(-142.25 -73.813)'
      />
      <path
        fill={color}
        d='M164.449,79.808l-.894-.054v1.9a.94.94,0,0,0,.118.536.5.5,0,0,0,.44.165,4.306,4.306,0,0,1,.495.022.864.864,0,0,1,.351.13q.33.179.329.921,0,.867-.385,1.059a1.3,1.3,0,0,1-.364.117,3.952,3.952,0,0,1-.461.02,3.029,3.029,0,0,1-2.145-.715,2.748,2.748,0,0,1-.771-2.118V79.754q-.661.055-1.1.054l-1.252-.054v1.9a.949.949,0,0,0,.116.536.506.506,0,0,0,.441.165,4.3,4.3,0,0,1,.495.022.978.978,0,0,1,.365.13q.316.179.316.921,0,.867-.385,1.059a1.33,1.33,0,0,1-.365.117,3.942,3.942,0,0,1-.461.02,3.036,3.036,0,0,1-2.146-.715,2.751,2.751,0,0,1-.77-2.118V79.754a3.948,3.948,0,0,1-.571.041.626.626,0,0,1-.495-.233,1.241,1.241,0,0,1-.2-.8,3.458,3.458,0,0,1,.069-.818.746.746,0,0,1,.192-.365.846.846,0,0,1,.551-.178l.453.054V76.04a3.7,3.7,0,0,1,.021-.467.865.865,0,0,1,.13-.33q.179-.344,1.059-.344.936,0,1.114.509a2.565,2.565,0,0,1,.069.7v1.307c.43-.009.839-.013,1.224-.013l1.128.013V76.04a3.875,3.875,0,0,1,.021-.467.974.974,0,0,1,.117-.33q.192-.344,1.073-.344c.623,0,.991.171,1.1.509a2.14,2.14,0,0,1,.083.7v1.348q.606-.054.915-.054a3.828,3.828,0,0,1,.467.02,1,1,0,0,1,.351.13q.371.179.372,1.059t-.372,1.059a1.277,1.277,0,0,1-.364.117A3.951,3.951,0,0,1,164.449,79.808Z'
        transform='translate(-142.87 -73.991)'
      />
      <path
        fill={color}
        d='M171.844,85.077a2.921,2.921,0,0,1-2.3-1.052,3.773,3.773,0,0,1-.915-2.565V79.054a4.076,4.076,0,0,1,.02-.482,1.337,1.337,0,0,1,.117-.358q.192-.371,1.073-.371.963,0,1.129.523a2.177,2.177,0,0,1,.083.7v2.407a1.1,1.1,0,0,0,1.189,1.2,1.2,1.2,0,0,0,.88-.329,1.159,1.159,0,0,0,.337-.866V79.039a3.872,3.872,0,0,1,.021-.474,1.091,1.091,0,0,1,.13-.365q.179-.357,1.059-.358t1.058.371a1.27,1.27,0,0,1,.117.365,3.889,3.889,0,0,1,.021.474v4.868a3.757,3.757,0,0,1-.021.461.963.963,0,0,1-.13.35q-.206.357-1.059.358t-1.031-.33a1.082,1.082,0,0,1-.138-.564,1.374,1.374,0,0,1-.247.275,3.384,3.384,0,0,1-.372.3A1.836,1.836,0,0,1,171.844,85.077Z'
        transform='translate(-145.079 -74.474)'
      />
      <path
        fill={color}
        d='M183.429,74.178a.587.587,0,0,1,.365-.283,2.987,2.987,0,0,1,1.39,0,.581.581,0,0,1,.364.289,1.209,1.209,0,0,1,.125.364,4.326,4.326,0,0,1,.02.476v8.211a4.291,4.291,0,0,1-.02.474,1.01,1.01,0,0,1-.13.35q-.207.372-1.163.372t-1.065-.674a2.028,2.028,0,0,1-1.609.66,3.052,3.052,0,0,1-2.257-1.079,3.621,3.621,0,0,1,.007-5.041,3.056,3.056,0,0,1,2.25-1.087,1.931,1.931,0,0,1,1.581.687V75.009a3.878,3.878,0,0,1,.021-.474A1.107,1.107,0,0,1,183.429,74.178Zm-2.236,7.474a1.158,1.158,0,0,0,.867.372,1.093,1.093,0,0,0,.846-.372,1.188,1.188,0,0,0,.337-.818A1.269,1.269,0,0,0,182.92,80a1.084,1.084,0,0,0-.874-.385,1.11,1.11,0,0,0-.88.378,1.237,1.237,0,0,0-.33.833A1.162,1.162,0,0,0,181.193,81.652Z'
        transform='translate(-146.687 -73.813)'
      />
      <path
        fill={color}
        d='M195.377,80.588a1.671,1.671,0,0,1-.419,1.252,1.309,1.309,0,0,1-.928.406H190.77a.755.755,0,0,0,.454.646,1.812,1.812,0,0,0,.908.262,3.774,3.774,0,0,0,1.252-.166l.151-.054a1.4,1.4,0,0,1,.55-.151q.44,0,.756.619a1.514,1.514,0,0,1,.179.633q0,1.224-2.929,1.224a4.4,4.4,0,0,1-1.808-.351,3.06,3.06,0,0,1-1.231-.928,3.954,3.954,0,0,1-.866-2.49,3.6,3.6,0,0,1,1.12-2.771,3.961,3.961,0,0,1,2.8-1.038,3.125,3.125,0,0,1,2.806,1.348A2.793,2.793,0,0,1,195.377,80.588Zm-2.9.406q.523,0,.523-.441a.618.618,0,0,0-.241-.509,1.073,1.073,0,0,0-.687-.192,1.394,1.394,0,0,0-.887.365,1.023,1.023,0,0,0-.441.778Z'
        transform='translate(-148.286 -74.447)'
      />
    </g>
    <path
      fill={color}
      d='M127.773,78.482c.96-1.71,2.2-3.286,2.857-4.28,2.24-3.412,1.026-4.511,1.026-4.511a10.368,10.368,0,0,1-1.49,2.81c-1.988,2.788-3.051,4.012-3.85,5.042-.734-.454-1.4-.844-1.876-1.169a14.294,14.294,0,0,1-1.616-1.185,1.49,1.49,0,0,0,.752,1.559c.643.416,1.441.9,2.275,1.42a18.462,18.462,0,0,0-1.742,3.13c-1.184,2.542-.1,7.738,5.2,6.757a3.619,3.619,0,0,0,2.868-3.347C132.361,82.1,130.04,80.026,127.773,78.482ZM130.5,84.97c-.685.716-2.7,1.253-3.74-1.072-.625-1.392-.169-3.086.642-4.715C130.021,80.982,132.278,83.11,130.5,84.97Z'
      transform='translate(-122.799 -69.691)'
    />
    <path
      fill={color}
      d='M126.9,73.708a1.084,1.084,0,1,0-1.083-1.084A1.084,1.084,0,0,0,126.9,73.708Z'
      transform='translate(-123.295 -69.994)'
    />
  </svg>
)

VittudeLogo.defaultProps = {
  color: '#BDC3C7',
  width: 63,
  height: 19
}
