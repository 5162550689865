import React from 'react'

export const PaperPlane = ({ color, size }) => (
  <svg width={size} height={size} viewBox='0 0 10 10.004'>
    <path
      d='M309.368,390.04a1.132,1.132,0,0,0-1.161-.272l-7.739,2.593h-.007a1.133,1.133,0,0,0-.005,2.138l3.212,1.227,1.264,3.237a1.122,1.122,0,0,0,1.067.75h0a1.123,1.123,0,0,0,1.069-.75v-.008l2.572-7.75A1.125,1.125,0,0,0,309.368,390.04Z'
      transform='translate(-299.701 -389.709)'
      fill={color}
    />
  </svg>
)

PaperPlane.defaultProps = {
  color: '#FF7708',
  size: 10
}
