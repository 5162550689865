import React from 'react'

export const CalendarIcon = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.43164 4.57728C1.43164 4.28715 1.54689 4.00898 1.75204 3.80383C1.9572 3.59867 2.23545 3.48334 2.52558 3.48334H16.7468C17.0369 3.48334 17.3152 3.59867 17.5203 3.80383C17.7255 4.00898 17.8407 4.28715 17.8407 4.57728V16.6106C17.8407 16.9007 17.7255 17.1789 17.5203 17.3841C17.3152 17.5892 17.0369 17.7045 16.7468 17.7045H2.52558C2.23545 17.7045 1.9572 17.5892 1.75204 17.3841C1.54689 17.1789 1.43164 16.9007 1.43164 16.6106V4.57728Z'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1.43164 7.85907H17.8407'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.80762 5.12423V1.29544'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M13.4648 5.12423V1.29544'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill={color}
        d='M9.63005 10.3204C9.46894 10.3204 9.31143 10.3685 9.17746 10.4586C9.0435 10.5488 8.93911 10.677 8.87745 10.8269C8.81579 10.9769 8.79965 11.1419 8.83108 11.301C8.86251 11.4602 8.9401 11.6062 9.05402 11.721C9.16795 11.8357 9.31309 11.9139 9.47111 11.9456C9.62913 11.9772 9.79295 11.961 9.9418 11.8989C10.0907 11.8368 10.2179 11.7316 10.3074 11.5967C10.3969 11.4617 10.4447 11.3032 10.4447 11.1409C10.4447 10.9233 10.3589 10.7147 10.2061 10.5608C10.0533 10.4069 9.8461 10.3204 9.63005 10.3204Z'
      />
      <path
        fill={color}
        d='M13.7326 10.3204C13.5715 10.3204 13.414 10.3685 13.28 10.4586C13.146 10.5488 13.0416 10.677 12.98 10.8269C12.9183 10.9769 12.9022 11.1419 12.9336 11.301C12.965 11.4602 13.0426 11.6062 13.1566 11.721C13.2705 11.8357 13.4156 11.9139 13.5737 11.9456C13.7317 11.9772 13.8955 11.961 14.0443 11.8989C14.1932 11.8368 14.3204 11.7316 14.4099 11.5967C14.4994 11.4617 14.5472 11.3032 14.5472 11.1409C14.5472 10.9233 14.4614 10.7147 14.3086 10.5608C14.1558 10.4069 13.9486 10.3204 13.7326 10.3204Z'
      />
      <path
        fill={color}
        d='M5.52849 10.3204C5.36737 10.3204 5.20986 10.3685 5.0759 10.4586C4.94194 10.5488 4.83755 10.677 4.77589 10.8269C4.71423 10.9769 4.69808 11.1419 4.72952 11.301C4.76095 11.4602 4.83854 11.6062 4.95246 11.721C5.06639 11.8357 5.21153 11.9139 5.36955 11.9456C5.52757 11.9772 5.69138 11.961 5.84024 11.8989C5.98909 11.8368 6.1163 11.7316 6.20581 11.5967C6.29533 11.4617 6.34311 11.3032 6.34311 11.1409C6.34311 10.9233 6.25729 10.7147 6.10452 10.5608C5.95175 10.4069 5.74454 10.3204 5.52849 10.3204Z'
      />
      <path
        fill={color}
        d='M9.63005 13.6023C9.46894 13.6023 9.31143 13.6503 9.17746 13.7405C9.0435 13.8306 8.93911 13.9589 8.87745 14.1088C8.81579 14.2587 8.79965 14.4237 8.83108 14.5828C8.86251 14.742 8.9401 14.8881 9.05402 15.0028C9.16795 15.1176 9.31309 15.1958 9.47111 15.2274C9.62913 15.2591 9.79295 15.2428 9.9418 15.1807C10.0907 15.1186 10.2179 15.0134 10.3074 14.8785C10.3969 14.7436 10.4447 14.585 10.4447 14.4227C10.4447 14.2051 10.3589 13.9965 10.2061 13.8426C10.0533 13.6888 9.8461 13.6023 9.63005 13.6023Z'
      />
      <path
        fill={color}
        d='M13.7326 13.6023C13.5715 13.6023 13.414 13.6503 13.28 13.7405C13.146 13.8306 13.0416 13.9589 12.98 14.1088C12.9183 14.2587 12.9022 14.4237 12.9336 14.5828C12.965 14.742 13.0426 14.8881 13.1566 15.0028C13.2705 15.1176 13.4156 15.1958 13.5737 15.2274C13.7317 15.2591 13.8955 15.2428 14.0443 15.1807C14.1932 15.1186 14.3204 15.0134 14.4099 14.8785C14.4994 14.7436 14.5472 14.585 14.5472 14.4227C14.5472 14.2051 14.4614 13.9965 14.3086 13.8426C14.1558 13.6888 13.9486 13.6023 13.7326 13.6023Z'
      />
      <path
        fill={color}
        d='M5.52849 13.6023C5.36737 13.6023 5.20986 13.6503 5.0759 13.7405C4.94194 13.8306 4.83755 13.9589 4.77589 14.1088C4.71423 14.2587 4.69808 14.4237 4.72952 14.5828C4.76095 14.742 4.83854 14.8881 4.95246 15.0028C5.06639 15.1176 5.21153 15.1958 5.36955 15.2274C5.52757 15.2591 5.69138 15.2428 5.84024 15.1807C5.98909 15.1186 6.1163 15.0134 6.20581 14.8785C6.29533 14.7436 6.34311 14.585 6.34311 14.4227C6.34311 14.2051 6.25729 13.9965 6.10452 13.8426C5.95175 13.6888 5.74454 13.6023 5.52849 13.6023Z'
      />
    </svg>
  )
}

CalendarIcon.defaultProps = {
  color: '#000000',
  size: '17px'
}
