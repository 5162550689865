import React from 'react'

export const LinkedinWithoutBackground = ({ size, color }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 12 12'>
      <path
        fill={color}
        d='M1.484 2.967a1.484 1.484 0 100-2.967 1.484 1.484 0 000 2.967zM2.72 3.958H.247A.247.247 0 000 4.205v7.418c0 .136.11.247.247.247H2.72c.136 0 .247-.11.247-.247V4.205a.247.247 0 00-.247-.247zM10.088 3.546c-1.057-.362-2.379-.044-3.171.527a.247.247 0 00-.24-.186H4.205a.247.247 0 00-.247.247v7.418c0 .137.11.247.247.247h2.472c.137 0 .248-.11.248-.247v-5.33c.4-.345.914-.455 1.335-.276.409.173.643.594.643 1.155v4.451c0 .137.11.247.247.247h2.473c.136 0 .247-.11.247-.247V6.604c-.028-2.032-.984-2.785-1.782-3.058z'
      />
    </svg>
  )
}
LinkedinWithoutBackground.defaultProps = {
  color: '#0A142F',
  size: 12
}
