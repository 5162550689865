import React from 'react'

export const Comment = ({ color, size, height, ...props }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={size} viewBox='0 0 20 12.844' {...props}>
    <defs></defs>
    <g transform='translate(0 0)'>
      <path
        className='a'
        fill={color}
        d='M29.546,21.108a4.578,4.578,0,0,0,4.57-4.624,4.647,4.647,0,0,0-4.651-4.624c-.026,0-.064,0-.09,0a5.857,5.857,0,0,1,3.666-1.288A1.153,1.153,0,0,0,34.193,9.42a1.07,1.07,0,0,0-1.049-1.156,8.14,8.14,0,0,0-8.114,8.22v0A4.532,4.532,0,0,0,29.546,21.108Z'
        transform='translate(-25.03 -8.264)'
      />
      <path
        className='a'
        fill={color}
        d='M4.522,21.108A4.581,4.581,0,0,0,9.1,16.484,4.646,4.646,0,0,0,4.446,11.86c-.026,0-.063,0-.089,0a5.859,5.859,0,0,1,3.666-1.288A1.153,1.153,0,0,0,9.176,9.42,1.075,1.075,0,0,0,8.12,8.264,8.145,8.145,0,0,0,0,16.484v0A4.537,4.537,0,0,0,4.522,21.108Z'
        transform='translate(10.824 -8.264)'
      />
    </g>
  </svg>
)

Comment.defaultProps = {
  color: '#4e5657',
  size: 18
}
