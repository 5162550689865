import React from 'react'

export const BookmarkPrice = ({ color, size }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    fill='none'
    viewBox='0 0 16 16'
  >
    <mask
      id='mask0_48_1777'
      style={{ maskType: 'alpha' }}
      width='16'
      height='16'
      x='0'
      y='0'
      maskUnits='userSpaceOnUse'
    >
      <path fill={'#D9D9D9'} d='M0 0H16V16H0z'></path>
    </mask>
    <g mask='url(#mask0_48_1777)'>
      <path
        fill={color}
        d='M4.266 13.6a.66.66 0 01-.633-.059.621.621 0 01-.3-.558v-9.65c0-.366.13-.68.392-.942.26-.26.575-.391.941-.391h6.667c.367 0 .68.13.942.391.26.262.391.576.391.942v9.65c0 .245-.1.43-.3.558a.66.66 0 01-.633.059L8 12l-3.734 1.6z'
      ></path>
      <mask
        id='mask1_48_1777'
        style={{ maskType: 'alpha' }}
        width='12'
        height='13'
        x='2'
        y='1'
        maskUnits='userSpaceOnUse'
      >
        <path fill={'#D9D9D9'} d='M2 1.333H14V13.333H2z'></path>
      </mask>
      <g mask='url(#mask1_48_1777)'>
        <path
          fill='#fff'
          d='M7.954 11a.432.432 0 01-.44-.444v-.512a2.272 2.272 0 01-.869-.388 1.975 1.975 0 01-.605-.778.38.38 0 01-.006-.328.455.455 0 01.259-.25.384.384 0 01.319.006c.11.048.194.127.253.238.125.223.282.391.473.506.19.115.425.172.704.172.3 0 .556-.068.765-.205.209-.137.313-.35.313-.64 0-.258-.08-.464-.242-.616-.161-.152-.535-.324-1.122-.517-.63-.2-1.063-.438-1.298-.716-.235-.278-.352-.617-.352-1.017 0-.481.154-.855.462-1.122.308-.267.623-.419.946-.456v-.489A.433.433 0 017.954 3a.433.433 0 01.44.444v.49a1.8 1.8 0 01.726.272c.205.137.374.305.506.505a.345.345 0 01.039.322.415.415 0 01-.248.256.413.413 0 01-.319.005.796.796 0 01-.308-.216.983.983 0 00-.335-.24 1.211 1.211 0 00-.479-.082c-.323 0-.568.072-.737.216a.68.68 0 00-.253.54c0 .244.11.436.33.577.22.14.601.289 1.144.444.506.148.89.384 1.15.706.26.322.39.694.39 1.117 0 .525-.154.925-.462 1.2-.308.274-.69.444-1.144.51v.49a.432.432 0 01-.44.444z'
        ></path>
      </g>
    </g>
  </svg>
)

BookmarkPrice.defaultProps = {
  color: '#FFA938',
  size: '16px'
}
