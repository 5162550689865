export const WorldGlobeTimeZone = ({ size, color }) => (
  <svg width={size} height={size} viewBox={` 0 0 ${size} ${size}`}>
    <path
      fill={color}
      d='M21.81 13.347a10.751 10.751 0 00-2.543-9.314.207.207 0 01-.027-.039 10.795 10.795 0 00-16.01 0 .203.203 0 01-.026.039 10.769 10.769 0 000 14.391v.03a10.795 10.795 0 0010.143 3.355 6.045 6.045 0 108.463-8.463zm-3.675-1.257a6.078 6.078 0 00-.89.073v-.505h3.887a10.55 10.55 0 01-.117 1.17 6.003 6.003 0 00-2.88-.738zM3.523 17.483a9.832 9.832 0 01-2.228-5.825h3.886c.026 1.383.206 2.759.536 4.102a10.957 10.957 0 00-2.194 1.723zm0-12.522a10.959 10.959 0 002.198 1.727 18.632 18.632 0 00-.54 4.107H1.295A9.832 9.832 0 013.523 4.97V4.96zm15.407 0a9.832 9.832 0 012.227 5.834h-3.886a18.627 18.627 0 00-.535-4.102A10.96 10.96 0 0018.93 4.97V4.96zm-2.522 5.834h-4.75V8.204a10.833 10.833 0 004.284-1.075 17.77 17.77 0 01.466 3.666zm-4.75-3.455V1.334c1.727.272 3.226 2.19 4.055 4.931a9.901 9.901 0 01-4.055 1.075zm-.863-6.006V7.34A9.9 9.9 0 016.74 6.278c.83-2.755 2.328-4.672 4.055-4.944zm0 6.87v2.59h-4.75c.025-1.238.18-2.471.462-3.678a10.834 10.834 0 004.288 1.088zm-4.75 3.454h4.75v2.59c-1.487.061-2.945.427-4.284 1.076a17.778 17.778 0 01-.466-3.666zm4.75 3.455v6.006c-1.727-.272-3.226-2.19-4.055-4.931a9.901 9.901 0 014.055-1.075zm.863 5.997v-5.997c.392.017.783.055 1.17.116a6.016 6.016 0 00-.142 5.497 3.115 3.115 0 01-1.028.397v-.013zm1.689-6.662a11.063 11.063 0 00-1.689-.2v-2.59h4.75c0 .233 0 .466-.03.695a6.044 6.044 0 00-3.031 2.107v-.013zm5.008-10.13a10.076 10.076 0 01-1.87 1.507 9.801 9.801 0 00-2.335-4.098 9.93 9.93 0 014.205 2.59zM8.303 1.728A9.801 9.801 0 005.967 5.82c-.68-.424-1.308-.929-1.87-1.502a9.931 9.931 0 014.206-2.59zM4.098 18.134a10.074 10.074 0 011.87-1.507 9.802 9.802 0 002.335 4.098 9.932 9.932 0 01-4.205-2.591zm14.037 5.181a5.181 5.181 0 110-10.362 5.181 5.181 0 010 10.362z'
    />
    <path
      fill={color}
      d='M18.135 13.817a.432.432 0 00-.432.432v3.679l-1.995 1.597a.432.432 0 10.54.674l2.159-1.727a.432.432 0 00.16-.337v-3.886a.432.432 0 00-.432-.432z'
    />
  </svg>
)

WorldGlobeTimeZone.defaultProps = {
  color: '#FF7708',
  size: 25
}
