import React from 'react'

export const Magnifier = ({ color, size }) => (
  <svg
    width={size}
    color={color}
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.5538 13.8809C11.7274 13.3821 12.7267 12.5467 13.4254 11.48C14.1241 10.4132 14.4908 9.16324 14.4791 7.88813C14.4674 6.61301 14.0779 5.37014 13.3597 4.31642C12.6416 3.26269 11.6271 2.44559 10.4446 1.96843C9.26203 1.49128 7.96456 1.37544 6.71623 1.63568C5.4679 1.89593 4.32476 2.5204 3.4314 3.43033C2.53804 4.34026 1.93457 5.49474 1.6973 6.74764C1.46004 8.00055 1.59965 9.29565 2.09846 10.4692C2.42956 11.2485 2.9109 11.9549 3.51503 12.5482C4.11916 13.1414 4.83424 13.6098 5.61941 13.9266C6.40458 14.2434 7.24446 14.4024 8.09111 14.3946C8.93775 14.3868 9.77457 14.2122 10.5538 13.8809V13.8809Z'
      stroke='#FF7708'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.5906 12.4988L17.5909 17.5'
      stroke='#FF7708'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

Magnifier.defaultProps = {
  color: '#FF7708',
  size: '18px'
}
