export const Filter = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 18.795 18.19'>
      <g transform='translate(0.75 0.75)'>
        <path
          fill='none'
          stroke={color}
          d='M225.049,2008.866a.579.579,0,0,0-.576-.616h-16.14a.577.577,0,0,0-.576.616,8.668,8.668,0,0,0,6.911,7.873v7.607a.577.577,0,0,0,.947.444l2.311-1.926a.576.576,0,0,0,.208-.444v-5.681A8.669,8.669,0,0,0,225.049,2008.866Z'
          transform='translate(-207.755 -2008.25)'
        />
      </g>
    </svg>
  )
}

Filter.defaultProps = {
  color: '#FF7708',
  size: 19
}
