import React from 'react'

export const ClosingCircle = ({ size, color }) => (
  <svg width={size} height={size} viewBox='0 0 15.984 16'>
    <path
      fill={color}
      d='M154.536,148.067l-2.292,2.292,2.292,2.292a.625.625,0,1,1-.884.884l-2.292-2.292-2.292,2.292a.625.625,0,0,1-.884-.884l2.292-2.292-2.292-2.292a.625.625,0,0,1,.884-.884l2.292,2.292,2.292-2.292a.625.625,0,0,1,.884.884Z'
      transform='translate(-143.375 -142.406)'
    />
    <path
      fill={color}
      d='M15.984,8A8,8,0,0,0,8,0H7.984A8,8,0,0,0,2.343,13.657,7.947,7.947,0,0,0,7.984,16H8a8,8,0,0,0,7.984-8ZM7.992,14.75A6.75,6.75,0,1,1,14.734,8,6.758,6.758,0,0,1,7.992,14.75Z'
    />
  </svg>
)

ClosingCircle.defaultProps = {
  color: '#212121',
  size: 16
}
