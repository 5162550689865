export const Monthplan = ({ color, size, ...rest }) => (
  <svg width={size} height={size} viewBox='0 0 24 24'>
    <g
      id='Interface-Essential_Form-Edition_checklist'
      data-name='Interface-Essential / Form-Edition / checklist'
      transform='translate(-255.005 -4187)'
      {...rest}
    >
      <g id='Group_375' data-name='Group 375'>
        <path
          id='Rectangle-path_104'
          data-name='Rectangle-path 104'
          d='M255.755,4189.25a1.5,1.5,0,0,1,1.5-1.5h19.5a1.5,1.5,0,0,1,1.5,1.5v19.5a1.5,1.5,0,0,1-1.5,1.5h-19.5a1.5,1.5,0,0,1-1.5-1.5Z'
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1px'
        />
        <path
          id='Shape_1694'
          data-name='Shape 1694'
          d='M267.005,4191.5l-4.5,6-3-3'
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1px'
        />
        <path
          id='Shape_1695'
          data-name='Shape 1695'
          d='M269.255,4195.25h4.5'
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1px'
        />
        <path
          id='Shape_1696'
          data-name='Shape 1696'
          d='M267.005,4200.5l-4.5,6-3-3'
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1px'
        />
        <path
          id='Shape_1697'
          data-name='Shape 1697'
          d='M269.255,4204.25h4.5'
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1px'
        />
      </g>
    </g>
  </svg>
)

Monthplan.defaultProps = {
  size: 19,
  color: '#212121'
}
