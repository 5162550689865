import React from 'react'

export const Linkedin = ({ size, color }) => (
  <svg width={size} height={size} viewBox='0 0 14.61 14.61'>
    <path
      fill={color}
      d='M13.288,0H1.322A1.322,1.322,0,0,0,0,1.322V13.288A1.322,1.322,0,0,0,1.322,14.61H13.288a1.322,1.322,0,0,0,1.322-1.322V1.322A1.322,1.322,0,0,0,13.288,0ZM4.521,12.615A.385.385,0,0,1,4.136,13H2.5a.385.385,0,0,1-.385-.385V5.752A.385.385,0,0,1,2.5,5.368H4.136a.385.385,0,0,1,.385.385Zm-1.2-7.894A1.555,1.555,0,1,1,4.873,3.165,1.555,1.555,0,0,1,3.318,4.721Zm9.759,7.925a.354.354,0,0,1-.354.354H10.966a.354.354,0,0,1-.354-.354V9.427c0-.48.141-2.1-1.255-2.1-1.083,0-1.3,1.112-1.346,1.61v3.713A.354.354,0,0,1,7.657,13h-1.7a.354.354,0,0,1-.354-.354V5.721a.354.354,0,0,1,.354-.354h1.7a.354.354,0,0,1,.354.354v.6a2.412,2.412,0,0,1,2.268-1.067c2.813,0,2.8,2.628,2.8,4.072v3.321Z'
    />
  </svg>
)

Linkedin.defaultProps = {
  color: '#7F8C8D',
  size: 20
}
