export const Calendar = ({ color, ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17.5px'
      height='20px'
      viewBox='0 0 17.5 20'
      {...rest}
    >
      <path
        class='a'
        fill={color}
        d='M5.781,11.25H4.219a.47.47,0,0,1-.469-.469V9.219a.47.47,0,0,1,.469-.469H5.781a.47.47,0,0,1,.469.469v1.563A.47.47,0,0,1,5.781,11.25ZM10,10.781V9.219a.47.47,0,0,0-.469-.469H7.969a.47.47,0,0,0-.469.469v1.563a.47.47,0,0,0,.469.469H9.531A.47.47,0,0,0,10,10.781Zm3.75,0V9.219a.47.47,0,0,0-.469-.469H11.719a.47.47,0,0,0-.469.469v1.563a.47.47,0,0,0,.469.469h1.563A.47.47,0,0,0,13.75,10.781ZM10,14.531V12.969a.47.47,0,0,0-.469-.469H7.969a.47.47,0,0,0-.469.469v1.563A.47.47,0,0,0,7.969,15H9.531A.47.47,0,0,0,10,14.531Zm-3.75,0V12.969a.47.47,0,0,0-.469-.469H4.219a.47.47,0,0,0-.469.469v1.563A.47.47,0,0,0,4.219,15H5.781A.47.47,0,0,0,6.25,14.531Zm7.5,0V12.969a.47.47,0,0,0-.469-.469H11.719a.47.47,0,0,0-.469.469v1.563a.47.47,0,0,0,.469.469h1.563A.47.47,0,0,0,13.75,14.531ZM17.5,4.375v13.75A1.875,1.875,0,0,1,15.625,20H1.875A1.875,1.875,0,0,1,0,18.125V4.375A1.875,1.875,0,0,1,1.875,2.5H3.75V.469A.47.47,0,0,1,4.219,0H5.781A.47.47,0,0,1,6.25.469V2.5h5V.469A.47.47,0,0,1,11.719,0h1.563a.47.47,0,0,1,.469.469V2.5h1.875A1.875,1.875,0,0,1,17.5,4.375ZM15.625,17.891V6.25H1.875V17.891a.235.235,0,0,0,.234.234H15.391A.235.235,0,0,0,15.625,17.891Z'
      />
    </svg>
  )
}
Calendar.defaultProps = {
  color: '#bdc3c7'
}
