import React from 'react'

export const MenuBenefits = ({ color, ...rest }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.918'
    height='18.348'
    viewBox='0 0 18.918 18.348'
    {...rest}
  >
    <g transform='translate(-303.072 -1563.334)'>
      <g transform='translate(303.755 1564.25)'>
        <path
          fill='none'
          stroke={color || '#212121'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M319.87,1570.692a1.4,1.4,0,0,1-.415.674l-.748.676a1.406,1.406,0,0,0,0,2.089l.748.676a1.407,1.407,0,0,1-1.015,2.45l-1.007-.052a1.406,1.406,0,0,0-1.477,1.477l.052,1.007a1.407,1.407,0,0,1-2.451,1.017l-.676-.748a1.408,1.408,0,0,0-2.089,0l-.676.748a1.407,1.407,0,0,1-2.45-1.017l.054-1.007a1.407,1.407,0,0,0-1.477-1.477l-1.007.052a1.407,1.407,0,0,1-1.015-2.45l.748-.676a1.408,1.408,0,0,0,0-2.089l-.748-.676a1.407,1.407,0,0,1,1.013-2.45l1.007.052a1.408,1.408,0,0,0,1.48-1.477l-.054-1.007a1.407,1.407,0,0,1,2.45-1.018l.676.748a1.406,1.406,0,0,0,2.089,0l.676-.748a1.408,1.408,0,0,1,1.583-.354'
          transform='translate(-303.755 -1564.423)'
        />
        <path
          fill='none'
          stroke={color || '#212121'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M310.605,1571.478l1.935,2.58a1.155,1.155,0,0,0,1.807.051l8.3-9.859'
          transform='translate(-305.332 -1564.25)'
        />
      </g>
    </g>
  </svg>
)
