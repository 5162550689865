import React from 'react'

export const Notepad = ({ size, color }) => (
  <svg width='14.339' height={size} viewBox='0 0 14.339 16'>
    <path
      fill={color}
      d='M113.577,200.579A.579.579,0,0,0,113,200h-5.96a.579.579,0,1,0,0,1.157H113A.579.579,0,0,0,113.577,200.579Z'
      transform='translate(-103.459 -193.711)'
    />
    <path
      fill={color}
      d='M107.038,280a.579.579,0,1,0,0,1.157h3.62a.579.579,0,0,0,0-1.157Z'
      transform='translate(-103.459 -271.195)'
    />
    <path
      fill={color}
      d='M31.2,14.75h-2.11a1.258,1.258,0,0,1-1.262-1.25V2.5a1.258,1.258,0,0,1,1.262-1.25h7.758A1.258,1.258,0,0,1,38.115,2.5V6.344a.631.631,0,0,0,1.262,0V2.5A2.515,2.515,0,0,0,36.852,0H29.094A2.515,2.515,0,0,0,26.57,2.5v11A2.515,2.515,0,0,0,29.094,16H31.2a.625.625,0,1,0,0-1.25Z'
      transform='translate(-26.57)'
    />
    <path
      fill={color}
      d='M249.951,272.532a1.738,1.738,0,0,0-2.455,0l-3.177,3.17a.579.579,0,0,0-.145.241l-.692,2.278a.578.578,0,0,0,.708.726l2.336-.647a.579.579,0,0,0,.254-.148l3.17-3.164A1.738,1.738,0,0,0,249.951,272.532Zm-3.88,4.693-1.175.326.344-1.132,2.144-2.139.819.818Zm3.062-3.057-.112.112-.818-.818.112-.112a.579.579,0,0,1,.819.818Z'
      transform='translate(-236.119 -262.968)'
    />
    <path
      fill={color}
      d='M113,120h-5.96a.579.579,0,1,0,0,1.157H113A.579.579,0,1,0,113,120Z'
      transform='translate(-103.459 -116.226)'
    />
  </svg>
)

Notepad.defaultProps = {
  color: '#212121',
  size: 16
}
