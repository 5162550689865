export const QuestionMark = ({ color, ...rest }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    margin-left='10px'
    viewBox='0 0 20 20'
    {...rest}
  >
    <g transform='translate(-1022 -125)'>
      <path
        class='a'
        fill={color}
        d='M18,8A10,10,0,1,0,28,18,10,10,0,0,0,18,8Zm0,18.065A8.065,8.065,0,1,1,26.065,18,8.06,8.06,0,0,1,18,26.065Zm4.324-10.29c0,2.7-2.92,2.745-2.92,3.744v.256a.484.484,0,0,1-.484.484H17.08a.484.484,0,0,1-.484-.484v-.349c0-1.441,1.093-2.017,1.919-2.48.708-.4,1.142-.667,1.142-1.193,0-.7-.887-1.157-1.6-1.157-.935,0-1.367.443-1.973,1.208a.484.484,0,0,1-.672.086l-1.122-.851a.485.485,0,0,1-.107-.66,4.533,4.533,0,0,1,4.055-2.185C20.212,12.194,22.324,13.738,22.324,15.774Zm-2.631,6.742A1.694,1.694,0,1,1,18,20.823,1.7,1.7,0,0,1,19.694,22.516Z'
        transform='translate(1014 117)'
      />
    </g>
  </svg>
)

QuestionMark.defaultProps = {
  color: '#bdc3c7'
}
