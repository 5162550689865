import React from 'react'

export const Billet = ({ color, size }) => (
  <svg width={size} height={size} viewBox='0 0 23.887 14.308'>
    <path
      fill={color}
      d='M281.012,344.693a.448.448,0,0,0-.448.448v.958a.448.448,0,0,0,.9,0v-.958A.448.448,0,0,0,281.012,344.693Z'
      transform='translate(-267.153 -332.24)'
    />
    <path
      fill={color}
      d='M216.884,344.693a.448.448,0,0,0-.448.448v.958a.448.448,0,0,0,.9,0v-.958A.448.448,0,0,0,216.884,344.693Z'
      transform='translate(-206.857 -332.24)'
    />
    <path
      fill={color}
      d='M184.819,344.693a.448.448,0,0,0-.448.448v.958a.448.448,0,0,0,.9,0v-.958A.448.448,0,0,0,184.819,344.693Z'
      transform='translate(-176.707 -332.24)'
    />
    <path
      fill={color}
      d='M313.077,344.693a.448.448,0,0,0-.448.448v.958a.448.448,0,0,0,.9,0v-.958A.448.448,0,0,0,313.077,344.693Z'
      transform='translate(-297.302 -332.24)'
    />
    <path
      fill={color}
      d='M345.141,344.693a.448.448,0,0,0-.448.448v.958a.448.448,0,0,0,.9,0v-.958A.448.448,0,0,0,345.141,344.693Z'
      transform='translate(-327.45 -332.24)'
    />
    <path
      fill={color}
      d='M377.206,344.693a.448.448,0,0,0-.448.448v.958a.448.448,0,0,0,.9,0v-.958A.448.448,0,0,0,377.206,344.693Z'
      transform='translate(-357.599 -332.24)'
    />
    <path
      fill={color}
      d='M120.69,344.693a.448.448,0,0,0-.448.448v.958a.448.448,0,0,0,.9,0v-.958A.448.448,0,0,0,120.69,344.693Z'
      transform='translate(-116.41 -332.24)'
    />
    <path
      fill={color}
      d='M88.625,344.693a.448.448,0,0,0-.448.448v.958a.448.448,0,0,0,.9,0v-.958A.448.448,0,0,0,88.625,344.693Z'
      transform='translate(-86.261 -332.24)'
    />
    <path
      fill={color}
      d='M409.271,344.693a.448.448,0,0,0-.448.448v.958a.448.448,0,0,0,.9,0v-.958A.448.448,0,0,0,409.271,344.693Z'
      transform='translate(-387.748 -332.24)'
    />
    <path
      fill={color}
      d='M152.755,344.693a.448.448,0,0,0-.448.448v.958a.448.448,0,0,0,.9,0v-.958A.448.448,0,0,0,152.755,344.693Z'
      transform='translate(-146.559 -332.24)'
    />
    <path
      fill={color}
      d='M88.625,136.274a.448.448,0,0,0-.448.448V147.26a.448.448,0,0,0,.9,0V136.722A.448.448,0,0,0,88.625,136.274Z'
      transform='translate(-86.261 -136.274)'
    />
    <path
      fill={color}
      d='M409.271,136.274a.448.448,0,0,0-.448.448V147.26a.448.448,0,0,0,.9,0V136.722A.448.448,0,0,0,409.271,136.274Z'
      transform='translate(-387.748 -136.274)'
    />
    <path
      fill={color}
      d='M120.69,136.274a.448.448,0,0,0-.448.448V147.26a.448.448,0,0,0,.9,0V136.722A.448.448,0,0,0,120.69,136.274Z'
      transform='translate(-116.41 -136.274)'
    />
    <path
      fill={color}
      d='M152.755,136.274a.448.448,0,0,0-.448.448V147.26a.448.448,0,0,0,.9,0V136.722A.448.448,0,0,0,152.755,136.274Z'
      transform='translate(-146.559 -136.274)'
    />
    <path
      fill={color}
      d='M184.819,136.274a.448.448,0,0,0-.448.448V147.26a.448.448,0,0,0,.9,0V136.722A.448.448,0,0,0,184.819,136.274Z'
      transform='translate(-176.707 -136.274)'
    />
    <path
      fill={color}
      d='M216.884,136.274a.448.448,0,0,0-.448.448V147.26a.448.448,0,0,0,.9,0V136.722A.448.448,0,0,0,216.884,136.274Z'
      transform='translate(-206.857 -136.274)'
    />
    <path
      fill={color}
      d='M248.948,136.274a.448.448,0,0,0-.448.448v13.411a.448.448,0,1,0,.9,0V136.722A.448.448,0,0,0,248.948,136.274Z'
      transform='translate(-237.005 -136.274)'
    />
    <path
      fill={color}
      d='M441.783,150.133V136.722a.448.448,0,1,0-.9,0v13.411a.448.448,0,0,0,.9,0Z'
      transform='translate(-417.896 -136.274)'
    />
    <path
      fill={color}
      d='M56.561,136.274a.448.448,0,0,0-.448.448v13.411a.448.448,0,1,0,.9,0V136.722A.448.448,0,0,0,56.561,136.274Z'
      transform='translate(-56.113 -136.274)'
    />
    <path
      fill={color}
      d='M281.012,136.274a.448.448,0,0,0-.448.448V147.26a.448.448,0,0,0,.9,0V136.722A.448.448,0,0,0,281.012,136.274Z'
      transform='translate(-267.153 -136.274)'
    />
    <path
      fill={color}
      d='M313.077,136.274a.448.448,0,0,0-.448.448V147.26a.448.448,0,0,0,.9,0V136.722A.448.448,0,0,0,313.077,136.274Z'
      transform='translate(-297.302 -136.274)'
    />
    <path
      fill={color}
      d='M345.141,136.274a.448.448,0,0,0-.448.448V147.26a.448.448,0,0,0,.9,0V136.722A.448.448,0,0,0,345.141,136.274Z'
      transform='translate(-327.45 -136.274)'
    />
    <path
      fill={color}
      d='M377.206,136.274a.448.448,0,0,0-.448.448V147.26a.448.448,0,0,0,.9,0V136.722A.448.448,0,0,0,377.206,136.274Z'
      transform='translate(-357.599 -136.274)'
    />
  </svg>
)

Billet.defaultProps = {
  color: '#4e5657',
  size: 24
}
