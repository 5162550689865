import React from 'react'

export const CalendarDisabled = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 18.985 18.986'>
      <g fill='none' stroke={color} strokeLinejoin='round' strokeWidth='1.2'>
        <path
          strokeLinecap='round'
          d='M13.045 17.786a4.743 4.743 0 10-4.743-4.743 4.743 4.743 0 004.743 4.743zM14.821 11.264l-3.557 3.557M11.264 11.264l3.557 3.557'
          transform='translate(.6 .6)'
        ></path>
        <path
          strokeLinecap='round'
          d='M5.928 13.039H1.185a1.186 1.186 0 01-1.186-1.186V2.379a1.186 1.186 0 011.186-1.186h10.671a1.186 1.186 0 011.186 1.186v3.545'
          transform='translate(.6 .6)'
        ></path>
        <path d='M0 4.743h13.045' transform='translate(.6 .6)'></path>
        <path
          strokeLinecap='round'
          d='M3.552 2.371V0M9.48 2.371V0'
          transform='translate(.6 .6)'
        ></path>
      </g>
    </svg>
  )
}

CalendarDisabled.defaultProps = {
  color: '#FF7708',
  size: 18
}
