export const Check = ({ color, size }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
  >
    <g clip-path='url(#clip0_40_424)'>
      <path
        d='M5.99998 10.78L3.21998 8L2.27332 8.94L5.99998 12.6667L14 4.66667L13.06 3.72667L5.99998 10.78Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_40_424'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
Check.defaultProps = {
  color: '#fff',
  size: 24
}
