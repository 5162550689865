export const Helper = ({ bgColor, size, symbolColor }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 18 16'>
      <g transform='translate(1 0.295)'>
        <path
          fill={bgColor}
          d='M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z'
          transform='translate(0 -0.295)'
        />
        <text fill={symbolColor} fontSize='10px' fontWeight='600' transform='translate(-1 1.705)'>
          <tspan x='6.595' y='9'>
            ?
          </tspan>
        </text>
      </g>
    </svg>
  )
}

Helper.defaultProps = {
  bgColor: '#e8eaeb',
  size: '16px',
  symbolColor: '#7f8c8d'
}
