import React from 'react'
import styled from 'styled-components'
import { layout } from 'styled-system'

const TOP = 'top'
const DOWN = 'down'
const RIGHT = 'right'
const LEFT = 'left'

export const Arrow = ({ size, color, variant, ...props }) => {
  const rotateChevron = () => {
    switch (variant) {
      case TOP:
      default:
        return 0
      case DOWN:
        return 180
      case RIGHT:
        return -90
      case LEFT:
        return 90
    }
  }
  return (
    <StyledSvg
      width={size}
      height={size}
      viewBox='0 0 16 14'
      transform={`rotate(${rotateChevron()} 0 0)`}
      {...props}
    >
      <path
        fill={color}
        d='M-43.975-436.183l-5.477-5.491a1.877,1.877,0,0,1,0-2.651l5.477-5.491a.624.624,0,0,1,.884,0,.625.625,0,0,1,0,.884l-5.3,5.309h13.76A.625.625,0,0,1-34-443a.625.625,0,0,1-.625.626h-13.76l5.3,5.308a.625.625,0,0,1,0,.885.622.622,0,0,1-.441.182A.624.624,0,0,1-43.975-436.183Z'
        transform='translate(50 450)'
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  transition: transform 0.3s ease;
  ${layout}
`

Arrow.defaultProps = {
  size: 24,
  color: '#FF7708',
  variant: TOP
}
