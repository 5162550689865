import React from 'react'

export const Instagram = ({ size, color }) => (
  <svg width={size} height={size} viewBox='0 0 14.656 14.656'>
    <path
      fill={color}
      d='M11.407,5.4c1.957,0,2.188.007,2.961.043a4.056,4.056,0,0,1,1.361.252A2.427,2.427,0,0,1,17.12,7.085a4.056,4.056,0,0,1,.252,1.361c.035.773.043,1,.043,2.961s-.007,2.188-.043,2.961a4.056,4.056,0,0,1-.252,1.361,2.427,2.427,0,0,1-1.391,1.391,4.056,4.056,0,0,1-1.361.252c-.773.035-1,.043-2.961.043s-2.189-.007-2.961-.043a4.056,4.056,0,0,1-1.361-.252,2.427,2.427,0,0,1-1.391-1.391,4.056,4.056,0,0,1-.252-1.361c-.035-.773-.043-1-.043-2.961s.007-2.188.043-2.961a4.056,4.056,0,0,1,.252-1.361A2.427,2.427,0,0,1,7.085,5.694a4.056,4.056,0,0,1,1.361-.252c.773-.035,1-.043,2.961-.043m0-1.32c-1.99,0-2.24.008-3.021.044a5.379,5.379,0,0,0-1.779.341A3.747,3.747,0,0,0,4.464,6.607a5.379,5.379,0,0,0-.341,1.779c-.036.782-.044,1.031-.044,3.021s.008,2.24.044,3.021a5.379,5.379,0,0,0,.341,1.779A3.747,3.747,0,0,0,6.607,18.35a5.379,5.379,0,0,0,1.779.341c.782.036,1.031.044,3.021.044s2.24-.008,3.021-.044a5.379,5.379,0,0,0,1.779-.341,3.747,3.747,0,0,0,2.143-2.143,5.379,5.379,0,0,0,.341-1.779c.036-.782.044-1.031.044-3.021s-.008-2.24-.044-3.021a5.379,5.379,0,0,0-.341-1.779,3.747,3.747,0,0,0-2.143-2.143,5.379,5.379,0,0,0-1.779-.341c-.782-.036-1.031-.044-3.021-.044Z'
      transform='translate(-4.079 -4.079)'
    />

    <path
      fill={color}
      d='M130.4,126.635a3.763,3.763,0,1,0,3.763,3.763A3.763,3.763,0,0,0,130.4,126.635Zm0,6.206a2.443,2.443,0,1,1,2.443-2.443A2.443,2.443,0,0,1,130.4,132.841Z'
      transform='translate(-123.07 -123.07)'
    />
    <ellipse
      fill={color}
      cx='0.879'
      cy='0.879'
      rx='0.879'
      ry='0.879'
      transform='translate(10.36 2.537)'
    />
  </svg>
)

Instagram.defaultProps = {
  color: '#7F8C8D',
  size: 20
}
