const Payroll = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height='auto'
      fill='none'
      viewBox='0 0 26 21'
    >
      <path
        fill={color}
        fillRule='evenodd'
        d='M20.28 18.921c.287 0 .52.236.52.526v.264c0 .29-.233.526-.52.526a.523.523 0 01-.52-.526v-.264c0-.29.233-.526.52-.526z'
        clipRule='evenodd'
      ></path>
      <path
        fill={color}
        fillRule='evenodd'
        d='M20.857 19.412c-.127.057-.273.08-.404.08h-.346c-.132 0-.277-.023-.404-.08-.094-.043-.333-.18-.333-.471v-.546c0-.218.175-.395.39-.395.215 0 .39.177.39.395v.307h.26v-.307c0-.218.175-.395.39-.395.215 0 .39.177.39.395v.546c0 .291-.239.428-.333.47z'
        clipRule='evenodd'
      ></path>
      <path
        fill={color}
        fillRule='evenodd'
        d='M18.85 3.658c0-.218.174-.395.39-.395h2.08c.215 0 .39.177.39.395V16.66c0 .495-.078.99-.232 1.374a1.554 1.554 0 01-.307.503.759.759 0 01-.544.252h-.694a.759.759 0 01-.544-.252 1.554 1.554 0 01-.308-.503c-.153-.384-.231-.878-.231-1.374V3.658zm.78.395V16.66c0 .424.068.812.174 1.078a.79.79 0 00.157.261h.638a.79.79 0 00.156-.261c.107-.266.175-.654.175-1.078V4.053h-1.3zm.96 13.954zm-.62 0z'
        clipRule='evenodd'
      ></path>
      <path
        fill={color}
        fillRule='evenodd'
        d='M19.252 2.116c.2-.114.446-.166.681-.166h.694c.235 0 .48.052.681.166.193.11.402.318.402.623v.857a.392.392 0 01-.39.395.392.392 0 01-.39-.395v-.79l-.005-.002a.636.636 0 00-.299-.065h-.693a.636.636 0 00-.299.065l-.004.003v.79a.392.392 0 01-.39.394.392.392 0 01-.39-.395V2.74c0-.305.209-.513.402-.623z'
        clipRule='evenodd'
      ></path>
      <path
        fill={color}
        fillRule='evenodd'
        d='M19.593.837a.742.742 0 01.514-.205h.346c.189 0 .374.071.514.205s.223.322.223.522v.72a.392.392 0 01-.39.395.392.392 0 01-.39-.395V1.42h-.26v.658a.392.392 0 01-.39.395.392.392 0 01-.39-.395v-.72c0-.2.083-.388.223-.522zM21.45 3.658c0-.218.175-.395.39-.395.221 0 .421.041.596.136.177.096.3.232.39.375.116.187.151.432.167.613.017.196.017.408.017.579v2.902a.392.392 0 01-.39.395.392.392 0 01-.39-.395V4.974c0-.18 0-.36-.014-.517a1.392 1.392 0 00-.028-.192.325.325 0 00-.022-.068.266.266 0 00-.099-.102.47.47 0 00-.227-.042.392.392 0 01-.39-.395zM10.53 9.579c0-.145.116-.263.26-.263h7.02c.143 0 .26.118.26.263a.262.262 0 01-.26.263h-7.02a.262.262 0 01-.26-.263zM21.97 9.579c0-.145.116-.263.26-.263h1.04c.144 0 .26.118.26.263a.262.262 0 01-.26.263h-1.04a.262.262 0 01-.26-.263zM10.53 11.158c0-.146.116-.263.26-.263h7.02c.143 0 .26.117.26.263a.262.262 0 01-.26.263h-7.02a.262.262 0 01-.26-.263zM21.97 11.158c0-.146.116-.263.26-.263h1.04c.144 0 .26.117.26.263a.262.262 0 01-.26.263h-1.04a.262.262 0 01-.26-.263zM21.97 12.737c0-.146.116-.263.26-.263h1.04c.144 0 .26.117.26.263a.262.262 0 01-.26.263h-1.04a.262.262 0 01-.26-.263z'
        clipRule='evenodd'
      ></path>
      <path
        fill={color}
        fillRule='evenodd'
        d='M1.01 6.755c.226-.216.528-.334.839-.334H17.39c.215 0 .39.177.39.395a.392.392 0 01-.39.395H1.849a.442.442 0 00-.305.118.358.358 0 00-.114.259v10.035c0 .092.038.186.114.258A.442.442 0 001.85 18h16.35c.216 0 .39.177.39.395a.392.392 0 01-.39.395H1.85c-.311 0-.613-.118-.84-.334a1.151 1.151 0 01-.36-.833V7.588c0-.317.133-.617.36-.833zM24.99 6.755a1.217 1.217 0 00-.839-.334h-.462a.392.392 0 00-.39.395c0 .218.174.395.39.395h.462c.118 0 .227.045.304.118a.357.357 0 01.115.259v10.035a.357.357 0 01-.115.258.442.442 0 01-.304.119H22.36a.392.392 0 00-.39.395c0 .218.175.395.39.395h1.791c.31 0 .613-.118.84-.334.227-.217.359-.516.359-.833V7.588c0-.317-.132-.617-.36-.833z'
        clipRule='evenodd'
      ></path>
      <path
        fill={color}
        fillRule='evenodd'
        d='M10.53 12.737c0-.146.116-.263.26-.263h7.02c.143 0 .26.117.26.263a.262.262 0 01-.26.263h-7.02a.262.262 0 01-.26-.263zM11.903 15.226a.258.258 0 01.36-.08l.672.436.672-.435a.258.258 0 01.28 0l.673.435.672-.435a.258.258 0 01.28 0l.673.435.672-.435a.258.258 0 01.28 0l.813.526c.12.078.156.241.079.364a.258.258 0 01-.36.08l-.672-.436-.672.435a.258.258 0 01-.28 0l-.673-.435-.672.435a.258.258 0 01-.28 0l-.673-.435-.672.435a.258.258 0 01-.28 0l-.813-.526a.265.265 0 01-.079-.364zM4.87 11.12a.965.965 0 01.619-.225h.88c.216 0 .39.177.39.395a.392.392 0 01-.39.394h-.88a.191.191 0 00-.19.157.196.196 0 00.118.217l-.145.367m.145-.367l-.145.367.145-.367zm0 0l.895.362c.209.085.383.242.49.442a.994.994 0 01-.234 1.229.965.965 0 01-.618.225h-.88a.392.392 0 01-.39-.395c0-.218.174-.395.39-.395h.88a.19.19 0 00.187-.157.196.196 0 00-.116-.216l.145-.367-.145.367-.894-.362a.976.976 0 01-.492-.442.994.994 0 01.235-1.23'
        clipRule='evenodd'
      ></path>
      <path
        fill={color}
        fillRule='evenodd'
        d='M5.72 10.566c.215 0 .39.176.39.394v.33a.392.392 0 01-.39.394.392.392 0 01-.39-.394v-.33c0-.218.175-.394.39-.394zM5.72 13.526c.215 0 .39.177.39.395v.329a.392.392 0 01-.39.395.392.392 0 01-.39-.395v-.329c0-.218.175-.395.39-.395z'
        clipRule='evenodd'
      ></path>
      <path
        fill={color}
        fillRule='evenodd'
        d='M4.576 9.81a2.957 2.957 0 013.258.655c.418.424.703.963.819 1.55a3.06 3.06 0 01-.17 1.748 3.018 3.018 0 01-1.102 1.358 2.964 2.964 0 01-3.775-.376 3.053 3.053 0 01-.372-3.821 2.998 2.998 0 011.342-1.115zm1.575.601a2.186 2.186 0 00-1.277.128c-.404.169-.749.456-.992.824a2.257 2.257 0 00.275 2.824 2.191 2.191 0 002.79.278c.364-.246.647-.595.815-1.004a2.262 2.262 0 00-.48-2.437 2.201 2.201 0 00-1.13-.613z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}

Payroll.defaultProps = {
  size: '22px',
  color: '#000'
}

export { Payroll }
