export const Thunder = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16.625' height='19' viewBox='0 0 18 20' {...rest}>
    <path
      class='a'
      fill={color}
      d='M11.3606 0.0673796C11.7459 0.216356 12 0.586963 12 1.0001V7.0001H17C17.3958 7.0001 17.7544 7.23357 17.9145 7.59555C18.0746 7.95753 18.0062 8.37989 17.7399 8.67277L7.73994 19.6728C7.46204 19.9785 7.02475 20.0818 6.63941 19.9328C6.25406 19.7838 6 19.4132 6 19.0001V13.0001H1C0.604187 13.0001 0.245619 12.7666 0.0854899 12.4047C-0.0746394 12.0427 -0.00619149 11.6203 0.260064 11.3274L10.2601 0.327429C10.538 0.0217311 10.9753 -0.0815967 11.3606 0.0673796ZM3.26056 11.0001H8V16.4135L14.7395 9.0001H10V3.58671L3.26056 11.0001Z'
    />
  </svg>
)

Thunder.defaultProps = {
  color: '#BDC3C7'
}
