import React from 'react'

export const LobbySuccess = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24'>
      <g transform='translate(-399.005 -4335)'>
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M405.005,4348.225l2.45,3.477a1.049,1.049,0,0,0,1.707.051l7.843-9.923'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M411.005,4358.25a11.25,11.25,0,1,0-11.25-11.25A11.25,11.25,0,0,0,411.005,4358.25Z'
        />
      </g>
    </svg>
  )
}

LobbySuccess.defaultProps = {
  color: '#27AE60',
  size: 22
}
