export const User = ({ color, ...rest }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16.625'
    height='19'
    viewBox='0 0 16.625 19'
    {...rest}
  >
    <path
      class='a'
      fill={color}
      d='M11.637,11.281c-1.065,0-1.577.594-3.325.594s-2.256-.594-3.325-.594A4.989,4.989,0,0,0,0,16.269v.95A1.782,1.782,0,0,0,1.781,19H14.844a1.782,1.782,0,0,0,1.781-1.781v-.95A4.989,4.989,0,0,0,11.637,11.281Zm3.206,5.938H1.781v-.95a3.212,3.212,0,0,1,3.206-3.206,14.529,14.529,0,0,0,3.325.594,14.39,14.39,0,0,0,3.325-.594,3.212,3.212,0,0,1,3.206,3.206ZM8.313,10.688A5.344,5.344,0,1,0,2.969,5.344,5.345,5.345,0,0,0,8.313,10.688Zm0-8.906A3.563,3.563,0,1,1,4.75,5.344,3.568,3.568,0,0,1,8.313,1.781Z'
    />
  </svg>
)

User.defaultProps = {
  color: '#BDC3C7'
}
