export const Remove = ({ size = 24, color = '#FF7708' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
  >
    <g clip-path='url(#clip0_57_1560)'>
      <path d='M19 13H5V11H19V13Z' fill={color} />
    </g>
    <defs>
      <clipPath id='clip0_57_1560'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
