export const CalendarTwo = ({ color, size }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 18.665 18.666'>
      <g transform='translate(0.65 0.65)'>
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M303.755,2851.908a1.158,1.158,0,0,1,1.158-1.158h15.05a1.158,1.158,0,0,1,1.158,1.158v12.735a1.158,1.158,0,0,1-1.158,1.158h-15.05a1.158,1.158,0,0,1-1.158-1.158Z'
          transform='translate(-303.755 -2848.435)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M303.755,2856.75H321.12'
          transform='translate(-303.755 -2849.804)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M309.755,2851.8v-4.052'
          transform='translate(-305.124 -2847.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M320.255,2851.8v-4.052'
          transform='translate(-307.52 -2847.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M308.544,2860.5a.289.289,0,1,0,.289.289.29.29,0,0,0-.289-.289'
          transform='translate(-304.782 -2850.66)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M308.544,2865.75a.29.29,0,1,0,.289.29.29.29,0,0,0-.289-.29'
          transform='translate(-304.782 -2851.858)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M314.919,2860.5a.289.289,0,1,0,.289.289.29.29,0,0,0-.289-.289'
          transform='translate(-306.237 -2850.66)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M314.919,2865.75a.29.29,0,1,0,.289.29.29.29,0,0,0-.289-.29'
          transform='translate(-306.237 -2851.858)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M321.294,2860.5a.289.289,0,1,0,.289.289.29.29,0,0,0-.289-.289'
          transform='translate(-307.692 -2850.66)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M321.294,2865.75a.29.29,0,1,0,.289.29.29.29,0,0,0-.289-.29'
          transform='translate(-307.692 -2851.858)'
        />
      </g>
    </svg>
  )
}
CalendarTwo.defaultProps = {
  color: '#bdc3c7',
  size: '20px'
}
