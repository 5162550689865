export const Download = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 12 16.75'>
      <g transform='translate(-1018.005 -453)'>
        <g transform='translate(1483.01 4205) rotate(180)'>
          <path fill='none' stroke={color} d='M459.005,3739.75v10.5' transform='translate(0 1)' />
          <path
            fill='none'
            stroke={color}
            d='M455.255,3743.5l3.75-3.75,3.75,3.75'
            transform='translate(0 1)'
          />
        </g>
        <path
          fill='none'
          stroke={color}
          d='M459.005,3739.75v10.5'
          transform='translate(-2720.995 928.005) rotate(-90)'
        />
      </g>
    </svg>
  )
}

Download.defaultProps = {
  color: '#FF7708',
  width: 12,
  height: 17
}
