import React from 'react'

export const MenuHelp = ({ color, ...rest }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='9.845'
    height='16'
    viewBox='0 0 9.845 16'
    {...rest}
  >
    <path
      fill={color || '#212121'}
      d='M302.063,409.287h0a1.313,1.313,0,0,0-1.313,1.313h0a1.312,1.312,0,0,0,1.312,1.313h0a1.313,1.313,0,0,0,1.313-1.312h0a1.313,1.313,0,0,0-1.312-1.313Z'
      transform='translate(-297.206 -395.913)'
    />
    <path
      fill={color || '#212121'}
      d='M294.331,372.019v-.084a1.04,1.04,0,0,1,.619-.965,4.918,4.918,0,0,0,2.991-4.531v-.056a4.923,4.923,0,0,0-9.845.056,1.313,1.313,0,1,0,2.626.038c0-.013,0-.025,0-.038a2.3,2.3,0,0,1,4.593,0v.038a2.3,2.3,0,0,1-1.4,2.078,3.661,3.661,0,0,0-2.214,3.38v.084a1.313,1.313,0,1,0,2.626.038c0-.013,0-.025,0-.038Z'
      transform='translate(-288.096 -361.533)'
    />
  </svg>
)
