import React from 'react'

export const MenuComment = ({ color, ...rest }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19.959'
    height='19.816'
    viewBox='0 0 19.959 19.816'
    {...rest}
  >
    <g transform='translate(-234.1 -532.1)'>
      <g transform='translate(234.75 532.75)'>
        <path
          fill='none'
          stroke={color || '#212121'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M244.625,532.75a8.629,8.629,0,0,0-7.338,13.188l-2.537,5.329,5.328-2.538a8.641,8.641,0,1,0,4.548-15.979Z'
          transform='translate(-234.75 -532.75)'
        />
        <path
          fill='none'
          stroke={color || '#212121'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M242.184,542.875a.309.309,0,1,0,.309.309.309.309,0,0,0-.309-.309'
          transform='translate(-236.011 -534.543)'
        />
        <path
          fill='none'
          stroke={color || '#212121'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M246.684,542.875a.309.309,0,1,0,.309.309.309.309,0,0,0-.309-.309'
          transform='translate(-236.808 -534.543)'
        />
        <path
          fill='none'
          stroke={color || '#212121'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M251.184,542.875a.309.309,0,1,0,.309.309.309.309,0,0,0-.309-.309'
          transform='translate(-237.605 -534.543)'
        />
      </g>
    </g>
  </svg>
)
