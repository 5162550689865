export const Refresh = ({ size, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height='auto'
    fill='none'
    viewBox='0 0 19 16'
  >
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      d='M4.719 9.592v3.188H1.53M14.281 6.405V3.217h3.188M14.481 3.217a6.909 6.909 0 01-7.64 11.156M4.518 12.778A6.908 6.908 0 0112.16 1.623'
    ></path>
  </svg>
)

Refresh.defaultProps = {
  color: '#212121',
  size: 16
}
