export const FactCheck = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_18_2869)'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M39.7948 6.2309H8.20507C6.03327 6.2309 4.25635 8.00782 4.25635 10.1796V37.8206C4.25635 39.9924 6.03327 41.7694 8.20507 41.7694H39.7948C41.9666 41.7694 43.7435 39.9924 43.7435 37.8206V10.1796C43.7435 8.00782 41.9666 6.2309 39.7948 6.2309ZM39.7948 37.8206H8.20507V10.1796H39.7948V37.8206Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M38.63 20.8806L35.8264 18.077L29.5677 24.3357L26.7838 21.5322L24 24.316L29.5677 29.9232L38.63 20.8806Z'
        fill={color}
      />
      <path d='M20.051 14.1284H10.1792V18.0771H20.051V14.1284Z' fill={color} />
      <path d='M20.051 22.0258H10.1792V25.9745H20.051V22.0258Z' fill={color} />
      <path d='M20.051 29.9232H10.1792V33.8719H20.051V29.9232Z' fill={color} />
    </g>
    <defs>
      <clipPath id='clip0_18_2869'>
        <rect
          width='47.3846'
          height='47.3846'
          fill='white'
          transform='translate(0.307617 0.3078)'
        />
      </clipPath>
    </defs>
  </svg>
)
