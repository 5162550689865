import React from 'react'
export const LinkAnchor = ({ color, ...rest }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17.004'
    height='17.001'
    viewBox='0 0 17.004 17.001'
    {...rest}
  >
    <g fill={color || '#4e5657'} transform='translate(0 -.049)'>
      <path
        d='M9.153 145.512l-2.6 2.6a2.76 2.76 0 01-3.9-3.9l2.6-2.6a.92.92 0 00-1.3-1.3l-2.6 2.6a4.6 4.6 0 006.505 6.5l2.6-2.6a.92.92 0 00-1.3-1.3z'
        transform='translate(0 -133.71)'
      ></path>
      <path
        d='M150.759 4.646a4.6 4.6 0 00-7.852-3.252L140.3 4a.92.92 0 101.3 1.3l2.6-2.6a2.76 2.76 0 013.9 3.9l-2.6 2.6a.92.92 0 101.3 1.3l2.6-2.6a4.57 4.57 0 001.359-3.254z'
        transform='translate(-133.754)'
      ></path>
      <path
        d='M111.3 117.809a.92.92 0 001.3 0l5.2-5.2a.92.92 0 00-1.3-1.3l-5.2 5.2a.92.92 0 000 1.3z'
        transform='translate(-106.055 -106.008)'
      ></path>
    </g>
  </svg>
)
