import React from 'react'

export const BackArrow = ({ color, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_121_1445)'>
        <path
          d='M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z'
          fill='#FF7708'
        />
      </g>
      <defs>
        <clipPath id='clip0_121_1445'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

BackArrow.defaultProps = {
  color: '#212121',
  size: 24
}
