import React from 'react'

export const Trash = ({ color, size }) => (
  <svg width='14.75' height={size} viewBox='0 0 14.75 16'>
    <path
      fill={color}
      d='M146.6,163a.625.625,0,0,0-.6.652l.313,7.154a.625.625,0,0,0,.624.6h.028a.625.625,0,0,0,.6-.652l-.312-7.154A.625.625,0,0,0,146.6,163Z'
      transform='translate(-142.034 -157.877)'
    />
    <path
      fill={color}
      d='M316.313,163.6,316,170.752a.625.625,0,0,0,.6.652h.028a.625.625,0,0,0,.624-.6l.313-7.154a.625.625,0,0,0-1.249-.055Z'
      transform='translate(-306.722 -157.878)'
    />
    <path
      fill={color}
      d='M236,163.625v7.154a.625.625,0,1,0,1.25,0v-7.154a.625.625,0,0,0-1.25,0Z'
      transform='translate(-229.222 -157.878)'
    />
    <path
      fill={color}
      d='M33.229,1.7H29.448V.979A1.877,1.877,0,0,0,27.573-.9H25.385A1.877,1.877,0,0,0,23.51.979V1.7H19.729a.625.625,0,0,0,0,1.25h.647l.762,9.875v.009a2.5,2.5,0,0,0,2.49,2.273h5.7a2.5,2.5,0,0,0,2.49-2.273v-.009l.762-9.875h.647a.625.625,0,0,0,0-1.25ZM24.76.979a.626.626,0,0,1,.625-.625h2.188A.626.626,0,0,1,28.2.979V1.7H24.76Zm5.814,11.742a1.244,1.244,0,0,1-1.244,1.133h-5.7a1.244,1.244,0,0,1-1.244-1.133L21.63,2.948h9.7Z'
      transform='translate(-19.104 0.896)'
    />
  </svg>
)

Trash.defaultProps = {
  color: '#FF7708',
  size: 16
}
