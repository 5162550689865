const WarningFilled = ({ color, size }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={size} height='auto' viewBox='0 0 20 17.844'>
    <path
      fill={color}
      d='M4.568,21.836h15.8a2.1,2.1,0,0,0,1.815-3.148l-7.9-13.65a2.1,2.1,0,0,0-3.63,0l-7.9,13.65A2.1,2.1,0,0,0,4.568,21.836Zm7.9-7.344a1.052,1.052,0,0,1-1.049-1.049v-2.1a1.049,1.049,0,1,1,2.1,0v2.1A1.052,1.052,0,0,1,12.469,14.492Zm1.049,4.2h-2.1v-2.1h2.1Z'
      transform='translate(-2.469 -3.992)'
    />
  </svg>
)

WarningFilled.defaultProps = {
  size: 20,
  color: '#f7b206'
}

export { WarningFilled }
