import React from 'react'

export const ConsultationChecked = ({ color, width, height }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 14.099 12.141'>
    <path
      fill='none'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      d='M1.045 7.448l2.45 3.477a1.049 1.049 0 001.707.051l7.843-9.923'
      data-name='Shape 1750'
    ></path>
  </svg>
)
