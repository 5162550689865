import React from 'react'

export const Info = ({ color, size }) => (
  <svg width={size} height={size} viewBox='0 0 12 12'>
    <g transform='translate(0 0.507)'>
      <g stroke={color} transform='translate(0 -0.507)'>
        <circle fill='none' cx='6' cy='6' r='4.6' />
      </g>
      <g transform='translate(5.349 2.362)'>
        <path
          fill={color}
          d='M50.836,32.459V29.448a.558.558,0,1,0-1.115,0v3.011a.558.558,0,1,0,1.115,0Zm-.558-5.492a.558.558,0,0,0-.558.558h0a.558.558,0,1,0,1.115,0h0A.558.558,0,0,0,50.279,26.967Z'
          transform='translate(-49.721 -26.967)'
        />
      </g>
    </g>
  </svg>
)

Info.defaultProps = {
  color: '#7f8c8d',
  size: 12
}
