import React from 'react'

export const Brain = ({ size, color }) => (
  <svg width={size} height={size} viewBox='0 0 16 16'>
    <path
      fill={color}
      d='M423.174,427.4a.605.605,0,0,0-1.148.383,6.157,6.157,0,0,0,.749,1.525.605.605,0,0,0,.981-.709A5.022,5.022,0,0,1,423.174,427.4Z'
      transform='translate(-408.718 -413.564)'
    />
    <path
      fill={color}
      d='M16,6.969a2.234,2.234,0,0,0-.73-1.663,2.253,2.253,0,0,0-1.8-2.872A2.263,2.263,0,0,0,11.245.594a2.21,2.21,0,0,0-1.291.415A2.43,2.43,0,0,0,6.025.99a2.21,2.21,0,0,0-1.264-.4,2.263,2.263,0,0,0-2.225,1.84,2.253,2.253,0,0,0-1.9,2.222A2.715,2.715,0,0,0,.8,5.527,2.487,2.487,0,0,0,0,7.281a2.253,2.253,0,0,0,2.25,2.25,6.927,6.927,0,0,0,1.042-.087,5.125,5.125,0,0,1,1-.069,2.254,2.254,0,0,0,2.11,1.469c.5,0,1.05-.012,1.622-.06a.628.628,0,0,0,.062.063,12.362,12.362,0,0,1,3.655,4.79.625.625,0,1,0,1.135-.523,15.113,15.113,0,0,0-1.724-2.922,8.866,8.866,0,0,0,2.078.277,2.206,2.206,0,0,0,2.2-2.2,2.183,2.183,0,0,0-.5-1.388A2.25,2.25,0,0,0,16,6.969Zm-2.25,1a.624.624,0,0,0-.109.01l-.531.094a.625.625,0,0,0,.109,1.24.953.953,0,1,1,.016,1.906,8.761,8.761,0,0,1-3.3-.754,7.231,7.231,0,0,0,1.034-.362.625.625,0,0,0-.505-1.143,10.374,10.374,0,0,1-4.06.634,1,1,0,0,1,0-2,4.041,4.041,0,0,0,2.027-.42,1.667,1.667,0,0,0,.879-1.487A1.877,1.877,0,0,0,7.44,3.812a.625.625,0,1,0,0,1.25.626.626,0,0,1,.625.625c0,.571-1.038.656-1.656.656a2.254,2.254,0,0,0-2.2,1.779,6.544,6.544,0,0,0-1.09.084,5.721,5.721,0,0,1-.866.075,1,1,0,0,1-1-1,1.48,1.48,0,0,1,.659-1.061.625.625,0,0,0,.251-.536L2.158,5.59a.625.625,0,0,0-.09-.291,1.509,1.509,0,0,1-.18-.644,1,1,0,0,1,.762-.971,2.266,2.266,0,0,0,2.111,1.44.625.625,0,0,0,0-1.25,1.008,1.008,0,0,1-1.013-1c0-.005,0-.01,0-.016s0-.01,0-.016a1.008,1.008,0,0,1,1.013-1,.96.96,0,0,1,.788.406c-.006.069-.01.139-.01.21a.625.625,0,1,0,1.25,0,1.193,1.193,0,0,1,2.364-.241.625.625,0,0,0,.6.5h.055a.628.628,0,0,0,.562-.337.97.97,0,0,1,.87-.538,1,1,0,1,1,.013,2,.625.625,0,0,0,0,1.25,2.253,2.253,0,0,0,2.087-1.411,1,1,0,0,1,.629,1.492.625.625,0,0,0-.091.325v.031a.625.625,0,0,0,.342.557.966.966,0,0,1,.528.88A1,1,0,0,1,13.753,7.969Z'
      transform='translate(-0.003 0)'
    />
  </svg>
)

Brain.defaultProps = {
  color: '#212121',
  size: 16
}
