import React from 'react'

export const UserAccountCircle = ({ backgroundColor, color, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.0004 12.4007C10.8311 12.4007 11.643 12.1543 12.3337 11.6928C13.0243 11.2313 13.5625 10.5755 13.8804 9.8081C14.1983 9.04071 14.2814 8.19625 14.1194 7.3816C13.9574 6.56695 13.5574 5.81863 12.9701 5.23129C12.3827 4.64396 11.6344 4.24402 10.8197 4.08198C10.0051 3.91993 9.1607 4.00322 8.39331 4.32109C7.62592 4.63895 6.97001 5.17709 6.50854 5.86772C6.04708 6.55835 5.80078 7.37041 5.80078 8.20103C5.80104 9.31477 6.24359 10.3827 7.03113 11.1703C7.81866 11.9578 8.8867 12.4005 10.0004 12.4007V12.4007Z'
        stroke='#212121'
        stroke-width='1.3'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.5291 17.1013C14.8045 16.3722 13.9429 15.7935 12.9938 15.3987C12.0447 15.0038 11.0269 14.8005 9.99892 14.8005C8.97098 14.8005 7.95318 15.0038 7.00409 15.3987C6.055 15.7935 5.19336 16.3722 4.46875 17.1013'
        stroke='#212121'
        stroke-width='1.3'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.99998 19C11.78 19 13.5201 18.4722 15.0001 17.4832C16.4801 16.4943 17.6337 15.0887 18.3149 13.4441C18.9961 11.7996 19.1743 9.98996 18.827 8.24414C18.4798 6.49831 17.6226 4.8947 16.3639 3.63603C15.1053 2.37736 13.5016 1.5202 11.7558 1.17293C10.01 0.825664 8.20038 1.00392 6.55585 1.6851C4.91132 2.36629 3.5057 3.51986 2.51677 4.9999C1.52784 6.47994 1 8.21999 1 10C1 12.387 1.9482 14.6762 3.63602 16.364C5.32384 18.0518 7.61304 19 9.99998 19V19Z'
        stroke='#212121'
        stroke-width='1.3'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

UserAccountCircle.defaultProps = {
  backgroundColor: '#fff',
  color: '#000000',
  size: 20
}
