import React from 'react'

export const Youtube = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox='0 0 21.389 14.973'>
    <path
      fill={color}
      d='M-36.008,94.026A2.679,2.679,0,0,0-37.9,92.135c-1.668-.447-8.357-.447-8.357-.447s-6.689,0-8.357.447A2.679,2.679,0,0,0-56.5,94.026a27.841,27.841,0,0,0-.447,5.148,27.841,27.841,0,0,0,.447,5.148,2.679,2.679,0,0,0,1.891,1.891c1.668.447,8.357.447,8.357.447s6.689,0,8.357-.447a2.679,2.679,0,0,0,1.891-1.891,27.842,27.842,0,0,0,.447-5.148A27.842,27.842,0,0,0-36.008,94.026Zm-12.387,8.357V95.966l5.557,3.209Z'
      transform='translate(56.95 -91.688)'
    />
  </svg>
)

Youtube.defaultProps = {
  color: '#7F8C8D',
  width: 28,
  height: 20
}
