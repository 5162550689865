import React from 'react'

export const ExportFile = ({ size, color }) => (
  <svg width={size} height={size} viewBox='0 0 20 21' fill='none'>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      d='M10 14.356V.856M13.218 4.07L10.004.856 6.789 4.07M13.857 7.286h3.857A1.232 1.232 0 0119 8.455v10.52a1.233 1.233 0 01-1.286 1.168H2.286A1.232 1.232 0 011 18.974V8.454a1.232 1.232 0 011.286-1.168h3.857'
    ></path>
  </svg>
)

ExportFile.defaultProps = {
  color: '#212121',
  size: 16
}
