export const VerifiedIcon = ({ width, height, color }) => (
  <svg width={width} height={height} fill='none' viewBox={`0 0 ${width} ${height}`}>
    <path fill={color} d='M5.5 9.5L2 6 .833 7.167 5.5 11.833l10-10L14.333.667 5.5 9.5z' />
  </svg>
)

VerifiedIcon.defaultProps = {
  width: '16',
  height: '12',
  color: '#FF7708'
}
