export const CalendarThree = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 32 32'>
      <path
        fill={color}
        d='M27,2.5H25.375V1.25a1.25,1.25,0,1,0-2.5,0V2.5H17.187V1.25a1.25,1.25,0,1,0-2.5,0V2.5H9.062V1.25a1.25,1.25,0,1,0-2.5,0V2.5H5a5.006,5.006,0,0,0-5,5V27a5.006,5.006,0,0,0,5,5H27a5.006,5.006,0,0,0,5-5,.83.83,0,0,0-.006-.125H32V7.5a5.006,5.006,0,0,0-5-5ZM5,5H6.562V6.25a1.25,1.25,0,0,0,2.5,0V5h5.625V6.25a1.25,1.25,0,1,0,2.5,0V5h5.688V6.25a1.25,1.25,0,0,0,2.5,0V5H27a2.5,2.5,0,0,1,2.5,2.5v3.375H2.5V7.5A2.5,2.5,0,0,1,5,5ZM29.5,27A2.5,2.5,0,0,1,27,29.5H5A2.5,2.5,0,0,1,2.5,27V13.754h27V26.875h.006A.83.83,0,0,0,29.5,27Z'
      />
    </svg>
  )
}
CalendarThree.defaultProps = {
  color: '#7f8c8d',
  size: '20px'
}
