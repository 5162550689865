export const Financing = ({ color, ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22.499'
      height='20'
      viewBox='0 0 22.499 20'
      {...rest}
    >
      <path
        class='a'
        fill={color}
        d='M21.9,8.75H20.744a6.277,6.277,0,0,0-1.973-2.477V3.75h-.625a5.518,5.518,0,0,0-3.164,1.023,3.356,3.356,0,0,0,.039-.4,4.375,4.375,0,1,0-8.6,1.09A6.263,6.263,0,0,0,2.647,10H2.209a.939.939,0,0,1-.918-1.125.962.962,0,0,1,.957-.75h.039a.235.235,0,0,0,.234-.234V7.109a.235.235,0,0,0-.234-.234,2.238,2.238,0,0,0-2.246,1.9A2.189,2.189,0,0,0,2.209,11.25h.313a6.225,6.225,0,0,0,2.5,4.973v3.152A.627.627,0,0,0,5.647,20h4.375a.627.627,0,0,0,.625-.625V17.5h2.5v1.875a.627.627,0,0,0,.625.625h4.375a.627.627,0,0,0,.625-.625v-3.16A6.431,6.431,0,0,0,19.994,15h1.9a.627.627,0,0,0,.625-.625v-5A.627.627,0,0,0,21.9,8.75ZM10.647,1.875a2.5,2.5,0,0,1,2.5,2.5,2.345,2.345,0,0,1-.09.625H8.772c-.176,0-.344.039-.52.051a2.42,2.42,0,0,1-.105-.676A2.5,2.5,0,0,1,10.647,1.875Zm10,11.25H19.061A7.079,7.079,0,0,1,16.9,15.289v2.836H15.022v-2.5H8.772v2.5H6.9V15.289A4.875,4.875,0,0,1,4.4,11.25,4.378,4.378,0,0,1,8.772,6.875h6.539A5.588,5.588,0,0,1,16.9,5.852V7.207c1.773,1.344,1.727,1.375,2.621,3.418h1.129ZM16.9,10a.625.625,0,1,0,.625.625A.627.627,0,0,0,16.9,10Z'
        transform='translate(-0.023)'
      />
    </svg>
  )
}
Financing.defaultProps = {
  color: '#bdc3c7'
}
