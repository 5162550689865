import React from 'react'

export const MenuLogoff = ({ color, ...rest }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16.839'
    height='15.8'
    viewBox='0 0 16.839 15.8'
    {...rest}
  >
    <g transform='translate(-255.101 -475.85)'>
      <g transform='translate(255.752 476.5)'>
        <path
          fill='none'
          stroke={color || '#212121'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M262.505,487H273.38'
          transform='translate(-257.843 -479.751)'
        />
        <path
          fill='none'
          stroke={color || '#212121'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M274.505,488.433l2.589-2.589-2.589-2.589'
          transform='translate(-261.557 -478.591)'
        />
        <path
          fill='none'
          stroke={color || '#212121'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M266.111,486.857v3.107a.992.992,0,0,1-.942,1.036h-8.474a.993.993,0,0,1-.941-1.036V477.536a.992.992,0,0,1,.941-1.036h8.474a.992.992,0,0,1,.942,1.036v3.107'
          transform='translate(-255.753 -476.5)'
        />
      </g>
    </g>
  </svg>
)
