import React from 'react'

export const PasswordEyeUnlock = ({ error, ...props }) => {
  const color = error ? '#db0000' : '#bdc3c7'
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24.214'
      height='15'
      viewBox='0 0 24.214 15'
      {...props}
    >
      <g transform='translate(0.857 -2.499)'>
        <g transform='translate(-255.756 -3341)'>
          <path
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5px'
            d='M267.005,3344.251c-4.031-.068-8.2,2.749-10.821,5.634a1.663,1.663,0,0,0,0,2.226c2.564,2.824,6.721,5.706,10.821,5.637,4.1.069,8.258-2.813,10.824-5.637a1.663,1.663,0,0,0,0-2.226C275.2,3347,271.036,3344.183,267.005,3344.251Z'
          />
          <path
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5px'
            d='M270.755,3351a3.749,3.749,0,1,1-1.1-2.653,3.751,3.751,0,0,1,1.1,2.653Z'
          />
        </g>
      </g>
    </svg>
  )
}
