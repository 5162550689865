import React from 'react'
import styled from 'styled-components'
const BOLETO = 'boleto'
const MASTERCARD = 'mastercard'
const ELO = 'elo'
const VISA = 'visa'
const AMERICA = 'america'
const DINNERS = 'dinners'

export const CardsPayment = ({ variant }) => {
  const changePosition = () => {
    switch (variant) {
      case BOLETO:
      default:
        return '-10px -8px'
      case MASTERCARD:
        return '-56px -9px'
      case ELO:
        return '-105px -13px'
      case VISA:
        return '-166px -16px'
      case AMERICA:
        return '-218px -9px'
      case DINNERS:
        return '-254px -9px'
    }
  }
  const changeWidth = () => {
    switch (variant) {
      case BOLETO:
      default:
        return '24px'
      case MASTERCARD:
        return '31px'
      case ELO:
        return '44px'
      case VISA:
        return '39px'
      case AMERICA:
        return '24px'
      case DINNERS:
        return '35px'
    }
  }
  const changeHeight = () => {
    switch (variant) {
      case BOLETO:
      default:
        return '26px'
      case MASTERCARD:
        return '25px'
      case ELO:
        return '18px'
      case VISA:
        return '12px'
      case AMERICA:
        return '24px'
      case DINNERS:
        return '27px'
    }
  }
  return <P position={changePosition()} width={changeWidth()} height={changeHeight()} />
}

const P = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  background-image: url(../../../static/images/sprite-footer-bandeiras.png);
  background-repeat: no-repeat;
  background-position: ${props => props.position};
`

CardsPayment.defaultProps = {
  variant: 'BOLETO'
}
