export const Handshake = ({ color, ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='16.8'
      viewBox='0 0 28 16.8'
      {...rest}
    >
      <path
        fill={color}
        d='M22.715,66.8l-2.083-2.082A2.461,2.461,0,0,0,18.9,64H8.977a2.469,2.469,0,0,0-1.732.713L5.162,66.8H0V77.983H2.8A1.4,1.4,0,0,0,4.2,76.6h.4l3.7,3.342a3.85,3.85,0,0,0,4.62.166,2.685,2.685,0,0,0,3.933-.354,2.454,2.454,0,0,0,2.8-.735L20.794,77.6a2.4,2.4,0,0,0,.477-1.006H23.8a1.4,1.4,0,0,0,1.4,1.387H28V66.8ZM2.1,76.583a.7.7,0,1,1,.7-.7A.7.7,0,0,1,2.1,76.583Zm17.062-.3-1.142,1.409a.353.353,0,0,1-.494.053l-1.046-.849-1.312,1.6a.585.585,0,0,1-.788.105l-1.61-1.378-.682.84a1.743,1.743,0,0,1-2.419.289L5.412,74.5H4.2v-5.6H6.033l2.7-2.7.249-.1h2.481L9.769,67.649a3.131,3.131,0,0,0-.192,4.432,3.169,3.169,0,0,0,4.441.192l.359-.328,4.734,3.841a.358.358,0,0,1,.052.494ZM23.8,74.5H20.772a2.452,2.452,0,0,0-.337-.337L15.942,70.51l.547-.5a.7.7,0,0,0,.044-.989l-.477-.512a.7.7,0,0,0-.989-.044l-2.415,2.214a1.113,1.113,0,0,1-1.514,0,1.038,1.038,0,0,1,.052-1.483l2.87-2.629a1.741,1.741,0,0,1,1.181-.459L18.9,66.1a.327.327,0,0,1,.241.1l2.7,2.695H23.8Zm2.1,2.087a.7.7,0,1,1,.7-.7A.7.7,0,0,1,25.9,76.583Z'
        transform='translate(0 -64)'
      />
    </svg>
  )
}

Handshake.defaultProps = {
  color: '#bdc3c7'
}
