import React from 'react'

export const Warning = ({ color, size }) => (
  <svg width='24.041' height='24' viewBox='0 0 24.041 24'>
    <g transform='translate(-350.984 -1907)'>
      <path
        fill='none'
        stroke='#fff'
        strokeLinecap='round'
        strokeWidth='1.5px'
        d='M363.005,1925.75a.375.375,0,1,0,.375.375.375.375,0,0,0-.375-.375h0'
      />
      <path
        fill='none'
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5px'
        d='M363.005,1922.75v-7.5'
      />
      <path
        fill='none'
        stroke='#fff'
        strokeLinecap='round'
        strokeWidth='1.5px'
        d='M364.626,1908.76a1.806,1.806,0,0,0-3.242,0l-9.473,19.3a1.521,1.521,0,0,0,1.366,2.192h19.456a1.521,1.521,0,0,0,1.366-2.192Z'
      />
    </g>
  </svg>
)

Warning.defaultProps = {
  size: 24
}
