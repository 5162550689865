import styled from 'styled-components'
import { layout } from 'styled-system'

const TOP = 'top'
const DOWN = 'down'
const RIGHT = 'right'
const LEFT = 'left'

export const LastPage = ({ width, height, color, variant, ...props }) => {
  const rotateIcon = () => {
    switch (variant) {
      case TOP:
      default:
        return 0
      case DOWN:
        return 180
      case RIGHT:
        return -90
      case LEFT:
        return 90
    }
  }
  return (
    <StyledSvg
      width={width}
      height={height}
      viewBox='0 0 10.07 12'
      transform={`rotate(${rotateIcon()} 0 0)`}
      {...props}
    >
      <path
        fill={color}
        d='M.411,5.006,5.269.138A.469.469,0,0,1,5.933.8L1.074,5.669a.469.469,0,0,0,0,.663L5.933,11.2a.469.469,0,1,1-.664.662L.411,6.994A1.408,1.408,0,0,1,.411,5.006Z'
      />
      <path
        fill={color}
        d='M.411,5.006,5.269.138A.469.469,0,0,1,5.933.8L1.074,5.669a.469.469,0,0,0,0,.663L5.933,11.2a.469.469,0,1,1-.664.662L.411,6.994A1.408,1.408,0,0,1,.411,5.006Z'
        transform='translate(4)'
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  transition: transform 0.3s ease;
  ${layout}
`

LastPage.defaultProps = {
  color: '#dcdee4',
  width: 10,
  height: 12,
  variant: RIGHT
}
