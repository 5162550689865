export const AddBox = ({ size = 24, color = '#FF7708' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
  >
    <g clip-path='url(#clip0_40_438)'>
      <path
        d='M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM11 17H13V13H17V11H13V7H11V11H7V13H11V17Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_40_438'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
