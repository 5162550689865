export const CircleCheck = ({ color, size }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 20 20'>
    <path
      fill={color}
      d='M18,8A10,10,0,1,0,28,18,10,10,0,0,0,18,8Zm0,1.935A8.065,8.065,0,1,1,9.935,18,8.06,8.06,0,0,1,18,9.935m5.653,5.253-.909-.916a.484.484,0,0,0-.684,0l-5.7,5.654L13.95,17.493a.484.484,0,0,0-.684,0l-.916.909a.484.484,0,0,0,0,.684l3.661,3.69a.484.484,0,0,0,.684,0l6.959-6.9a.484.484,0,0,0,0-.684Z'
      transform='translate(-8 -8)'
    />
  </svg>
)

CircleCheck.defaultProps = {
  color: '#fff',
  size: '20px'
}
