import React from 'react'

export const QuestionMarkCircle = ({ backgroundColor, color, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 12.0484C8.43881 12.0484 7.98387 12.5033 7.98387 13.0645C7.98387 13.6257 8.43881 14.0806 9 14.0806C9.56119 14.0806 10.0161 13.6257 10.0161 13.0645C10.0161 12.5033 9.56119 12.0484 9 12.0484ZM9.27835 11.1774H8.6977C8.45721 11.1774 8.26222 10.9824 8.26222 10.7419V10.7281C8.26222 8.17534 11.0725 8.41935 11.0725 6.83024C11.0725 6.10385 10.428 5.37097 8.98802 5.37097C7.93038 5.37097 7.38163 5.72113 6.83924 6.41221C6.69741 6.59294 6.43808 6.62977 6.24959 6.49844L5.77296 6.16638C5.56882 6.02416 5.524 5.73921 5.67697 5.54302C6.44727 4.55494 7.3612 3.91935 8.98802 3.91935C10.8867 3.91935 12.5242 4.99903 12.5242 6.83024C12.5242 9.27671 9.71383 9.14734 9.71383 10.7281V10.7419C9.71383 10.9824 9.51884 11.1774 9.27835 11.1774ZM9 1.16129C13.3048 1.16129 16.8387 4.64788 16.8387 9C16.8387 13.3291 13.3327 16.8387 9 16.8387C4.6726 16.8387 1.16129 13.3344 1.16129 9C1.16129 4.67408 4.66701 1.16129 9 1.16129ZM9 0C4.02979 0 0 4.03124 0 9C0 13.9717 4.02979 18 9 18C13.9702 18 18 13.9717 18 9C18 4.03124 13.9702 0 9 0Z'
        fill={color}
      />
    </svg>
  )
}

QuestionMarkCircle.defaultProps = {
  backgroundColor: '#fff',
  color: '#3C3C3C',
  size: 20
}
