import React from 'react'

export const BookmarkTag = ({ marked, color, size }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    fill='none'
    viewBox='0 0 23 23'
  >
    <g clipPath='url(#clip0)'>
      <path
        {...(marked && { fill: color })}
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M4.59 1.982h10.517a1.75 1.75 0 011.75 1.75l-.002 14.597a.877.877 0 01-1.495.62l-5.511-3.83-5.514 3.83a.875.875 0 01-1.493-.619V3.732a1.752 1.752 0 011.748-1.75z'
      ></path>
    </g>
    <defs>
      <clipPath id='clip0'>
        <path fill='#fff' d='M0 0H22.332V22.466H0z'></path>
      </clipPath>
    </defs>
  </svg>
)

BookmarkTag.defaultProps = {
  color: '#212121',
  size: '23px',
  marked: false
}
