import React from 'react'

export const BackArrowAlternative = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 22 12'>
      <path
        fill={color}
        d='M6.34074 11.25C6.48043 11.1095 6.55884 10.9194 6.55884 10.7212C6.55884 10.5231 6.48043 10.333 6.34074 10.1925L2.89074 6.74999H20.5532C20.7522 6.74999 20.9429 6.67098 21.0836 6.53032C21.2242 6.38967 21.3032 6.1989 21.3032 5.99999C21.3032 5.80108 21.2242 5.61031 21.0836 5.46966C20.9429 5.32901 20.7522 5.24999 20.5532 5.24999H2.91324L6.34074 1.82249C6.48296 1.68127 6.56326 1.48932 6.56396 1.28889C6.56466 1.08846 6.48572 0.895965 6.34449 0.753743C6.20326 0.61152 6.01132 0.531226 5.81089 0.530522C5.61046 0.529819 5.41796 0.608765 5.27574 0.749993L0.50574 5.52749C0.379792 5.65399 0.309082 5.82523 0.309082 6.00374C0.309082 6.18225 0.379792 6.35349 0.50574 6.47999L5.27574 11.25C5.34546 11.3203 5.42841 11.3761 5.51981 11.4142C5.6112 11.4522 5.70923 11.4718 5.80824 11.4718C5.90725 11.4718 6.00528 11.4522 6.09667 11.4142C6.18807 11.3761 6.27102 11.3203 6.34074 11.25Z'
      ></path>
    </svg>
  )
}

BackArrowAlternative.defaultProps = {
  color: '#0079C8',
  size: 22
}
