import React from 'react'

export const InternetSpeed = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24'>
      <g transform='translate(0.75 0.75)'>
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M312.005,242.292v-.285a3,3,0,0,1,6,0v.285'
          transform='translate(-303.755 -227.757)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M317.257,232.257l-2.25,6.75'
          transform='translate(-303.755 -227.757)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M309.382,234.507a.375.375,0,1,0,.375.375.375.375,0,0,0-.375-.375h0'
          transform='translate(-303.755 -227.757)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M322.132,238.257a.375.375,0,1,0,.375.375.375.375,0,0,0-.375-.375h0'
          transform='translate(-303.755 -227.757)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M320.632,234.507a.375.375,0,1,0,.373.375.375.375,0,0,0-.373-.375h0'
          transform='translate(-303.755 -227.757)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M312.382,231.507a.375.375,0,1,0,.375.375.375.375,0,0,0-.375-.375h0'
          transform='translate(-303.755 -227.757)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M324.436,245.142a15.753,15.753,0,0,0-18.862,0'
          transform='translate(-303.755 -227.757)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M315.005,250.257a11.25,11.25,0,1,0-11.25-11.25A11.25,11.25,0,0,0,315.005,250.257Z'
          transform='translate(-303.755 -227.757)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M307.882,238.257a.375.375,0,1,0,.375.375.375.375,0,0,0-.375-.375'
          transform='translate(-303.755 -227.757)'
        />
      </g>
    </svg>
  )
}

InternetSpeed.defaultProps = {
  color: '#FF7708',
  size: 22
}
