export const StarFilled = ({ height, width, color }) => (
  <svg height={height} width={width} viewBox='0 0 248.1 270.2'>
    <path
      fill={color}
      d='M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8
	c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3
	c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1
	c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3
	c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3
	l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7
	c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z'
    />
  </svg>
)

StarFilled.defaultProps = {
  color: '#FF7708',
  height: 16,
  width: 18
}
