import React, { useState } from 'react'
import styled from 'styled-components'

const FACEBOOK = 'facebook'
const FACEBOOK_2 = 'facebook-2'
const INSTAGRAM = 'instagram'
const TWITTER = 'twitter'
const TWITTER_2 = 'twitter-2'
const LINKEDIN = 'linkedin'
const YOUTUBE = 'youtube'
const WHATSAPP = 'whatsapp'
const EMAIL = 'email'

export const Social = ({ size, color, variant }) => {
  const [colored, setColored] = useState(color)
  const getViewBoxVector = () => {
    switch (variant) {
      case FACEBOOK:
      default:
        return '0 0 20.49 20.49'
      case FACEBOOK_2:
        return '0 0 167.657 167.657'
      case INSTAGRAM:
        return '0 0 20.448 20.447'
      case TWITTER:
        return '0 0 20.443 20.443'
      case TWITTER_2:
        return '0 0 512 512'
      case LINKEDIN:
        return '0 0 20.383 20.383'
      case YOUTUBE:
        return '0 0 28.905 20.365'
      case WHATSAPP:
        return '0 0 26.737 26.866'
      case EMAIL:
        return '0 0 24 18'
    }
  }
  const getPathVector = () => {
    switch (variant) {
      case FACEBOOK:
      default:
        return (
          <>
            <path
              fill={colored}
              d='M20.49,10.245A10.245,10.245,0,1,0,8.644,20.366V13.207h-2.6V10.245h2.6V7.988A3.615,3.615,0,0,1,12.514,4a15.755,15.755,0,0,1,2.293.2V6.723H13.515a1.481,1.481,0,0,0-1.67,1.6v1.922h2.841l-.454,2.961H11.846v7.159A10.248,10.248,0,0,0,20.49,10.245Z'
            />
            <path
              fill='#FFF'
              d='M310.19,209.2l.454-2.961H307.8v-1.922a1.481,1.481,0,0,1,1.67-1.6h1.292V200.2a15.754,15.754,0,0,0-2.293-.2,3.615,3.615,0,0,0-3.87,3.986v2.257H302V209.2h2.6v7.159a10.354,10.354,0,0,0,3.2,0V209.2Z'
              transform='translate(-295.957 -195.998)'
            />
          </>
        )
      case FACEBOOK_2:
        return (
          <>
            <path
              fill={colored}
              d='M83.829.349C37.532.349 0 37.881 0 84.178c0 41.523 30.222 75.911 69.848 82.57v-65.081H49.626v-23.42h20.222V60.978c0-20.037 12.238-30.956 30.115-30.956 8.562 0 15.92.638 18.056.919v20.944l-12.399.006c-9.72 0-11.594 4.618-11.594 11.397v14.947h23.193l-3.025 23.42H94.026v65.653c41.476-5.048 73.631-40.312 73.631-83.154 0-46.273-37.532-83.805-83.828-83.805z'
            ></path>
          </>
        )
      case INSTAGRAM:
        return (
          <>
            <path
              fill={colored}
              d='M14.3,5.921c2.73,0,3.053.01,4.131.06a5.659,5.659,0,0,1,1.9.352,3.386,3.386,0,0,1,1.94,1.94,5.658,5.658,0,0,1,.352,1.9c.049,1.078.06,1.4.06,4.131s-.01,3.053-.06,4.131a5.659,5.659,0,0,1-.352,1.9,3.386,3.386,0,0,1-1.94,1.94,5.659,5.659,0,0,1-1.9.352c-1.078.049-1.4.06-4.131.06s-3.053-.01-4.131-.06a5.659,5.659,0,0,1-1.9-.352,3.386,3.386,0,0,1-1.94-1.94,5.659,5.659,0,0,1-.352-1.9c-.049-1.078-.06-1.4-.06-4.131s.01-3.053.06-4.131a5.659,5.659,0,0,1,.352-1.9,3.386,3.386,0,0,1,1.94-1.94,5.659,5.659,0,0,1,1.9-.352c1.078-.049,1.4-.06,4.131-.06m0-1.842c-2.777,0-3.125.012-4.215.062a7.5,7.5,0,0,0-2.482.475,5.228,5.228,0,0,0-2.99,2.99,7.5,7.5,0,0,0-.475,2.482c-.05,1.09-.062,1.439-.062,4.215s.012,3.125.062,4.215A7.5,7.5,0,0,0,4.616,21a5.228,5.228,0,0,0,2.99,2.99,7.5,7.5,0,0,0,2.482.475c1.09.05,1.439.062,4.215.062s3.125-.012,4.215-.062A7.5,7.5,0,0,0,21,23.99,5.228,5.228,0,0,0,23.99,21a7.5,7.5,0,0,0,.475-2.482c.05-1.09.062-1.439.062-4.215s-.012-3.125-.062-4.215a7.5,7.5,0,0,0-.475-2.482A5.228,5.228,0,0,0,21,4.616a7.5,7.5,0,0,0-2.482-.475c-1.09-.05-1.439-.062-4.215-.062Z'
              transform='translate(-4.079 -4.079)'
            />
            <path
              fill={colored}
              d='M131.885,126.635a5.25,5.25,0,1,0,5.25,5.25A5.25,5.25,0,0,0,131.885,126.635Zm0,8.658a3.408,3.408,0,1,1,3.408-3.408A3.408,3.408,0,0,1,131.885,135.293Z'
              transform='translate(-121.661 -121.661)'
            />
            <ellipse
              fill={colored}
              cx='1.227'
              cy='1.227'
              rx='1.227'
              ry='1.227'
              transform='translate(14.454 3.539)'
            />
          </>
        )
      case TWITTER:
        return (
          <>
            <path
              fill={colored}
              d='M17.887,20.443H2.555A2.556,2.556,0,0,1,0,17.887V2.555A2.556,2.556,0,0,1,2.555,0H17.887a2.556,2.556,0,0,1,2.555,2.555V17.887A2.556,2.556,0,0,1,17.887,20.443Z'
            />
            <g transform='translate(3.838 5.029)'>
              <path
                fill='#FFF'
                d='M79.112,108.785a7.408,7.408,0,0,0,7.457-7.456c0-.112,0-.225-.005-.337a5.339,5.339,0,0,0,1.308-1.359,5.312,5.312,0,0,1-1.508.414,2.637,2.637,0,0,0,1.155-1.451,5.213,5.213,0,0,1-1.666.634,2.621,2.621,0,0,0-4.533,1.794,2.9,2.9,0,0,0,.066.6,7.442,7.442,0,0,1-5.4-2.739,2.631,2.631,0,0,0,.813,3.5,2.651,2.651,0,0,1-1.186-.327v.036a2.626,2.626,0,0,0,2.1,2.571,2.636,2.636,0,0,1-1.181.046,2.621,2.621,0,0,0,2.448,1.819,5.256,5.256,0,0,1-3.256,1.124,5.042,5.042,0,0,1-.624-.036,7.455,7.455,0,0,0,4.012,1.17'
                transform='translate(-75.1 -98.4)'
              />
            </g>
          </>
        )
      case TWITTER_2:
        return (
          <>
            <path
              fill={colored}
              d='M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z'
            />
          </>
        )
      case LINKEDIN:
        return (
          <>
            <path
              fill={colored}
              d='M18.539,0H1.844A1.844,1.844,0,0,0,0,1.844v16.7a1.844,1.844,0,0,0,1.844,1.844h16.7a1.844,1.844,0,0,0,1.844-1.844V1.844A1.844,1.844,0,0,0,18.539,0ZM6.307,17.6a.537.537,0,0,1-.537.537H3.487A.537.537,0,0,1,2.95,17.6V8.025a.537.537,0,0,1,.537-.537H5.771a.537.537,0,0,1,.537.537ZM4.629,6.586A2.17,2.17,0,1,1,6.8,4.416,2.17,2.17,0,0,1,4.629,6.586ZM18.244,17.643a.493.493,0,0,1-.493.493H15.3a.493.493,0,0,1-.493-.493V13.152c0-.67.2-2.936-1.751-2.936-1.511,0-1.817,1.551-1.878,2.247v5.18a.493.493,0,0,1-.493.493H8.313a.493.493,0,0,1-.493-.493V7.982a.493.493,0,0,1,.493-.493h2.371a.493.493,0,0,1,.493.493v.835a3.365,3.365,0,0,1,3.165-1.489c3.925,0,3.9,3.667,3.9,5.681v4.634Z'
            />
          </>
        )
      case YOUTUBE:
        return (
          <>
            <path
              fill={colored}
              d='M36.3,37.18a3.632,3.632,0,0,0-2.555-2.572C31.492,34,22.453,34,22.453,34s-9.039,0-11.293.608A3.632,3.632,0,0,0,8.6,37.18a38.1,38.1,0,0,0-.6,7,38.1,38.1,0,0,0,.6,7,3.632,3.632,0,0,0,2.555,2.572c2.254.608,11.293.608,11.293.608s9.039,0,11.293-.608A3.632,3.632,0,0,0,36.3,51.185a38.1,38.1,0,0,0,.6-7A38.1,38.1,0,0,0,36.3,37.18Z'
              transform='translate(-8 -34)'
            />
            <path
              fill='#FFF'
              d='M78,78.427l7.555-4.3L78,69.831Z'
              transform='translate(-66.504 -63.946)'
            />
          </>
        )
      case WHATSAPP:
        return (
          <>
            <path
              fill={colored}
              d='M22.842 3.9A13.316 13.316 0 001.889 19.967L0 26.866l7.057-1.851a13.3 13.3 0 006.362 1.62h.006A13.318 13.318 0 0022.842 3.9zm-9.417 20.487a11.051 11.051 0 01-5.632-1.542l-.4-.24L3.196 23.7l1.118-4.08-.264-.42a11.065 11.065 0 119.374 5.185zm6.069-8.287c-.333-.167-1.968-.971-2.273-1.082s-.527-.166-.748.167-.859 1.082-1.053 1.3-.388.25-.721.083a9.086 9.086 0 01-2.675-1.651 10.034 10.034 0 01-1.85-2.3c-.194-.333 0-.5.146-.679a9.41 9.41 0 00.83-1.138.612.612 0 00-.028-.583c-.083-.166-.748-1.8-1.025-2.47-.27-.648-.544-.561-.749-.571s-.416-.012-.638-.012a1.222 1.222 0 00-.887.416 3.731 3.731 0 00-1.163 2.775A6.471 6.471 0 008.018 13.8a14.827 14.827 0 005.682 5.023 19.039 19.039 0 001.9.7 4.56 4.56 0 002.1.132 3.427 3.427 0 002.245-1.582 2.779 2.779 0 00.194-1.582c-.089-.142-.312-.225-.645-.391zm0 0'
            ></path>
          </>
        )
      case EMAIL:
        return (
          <>
            <path
              fill={colored}
              d='M21.75,64H2.25A2.251,2.251,0,0,0,0,66.25v13.5A2.251,2.251,0,0,0,2.25,82h19.5A2.251,2.251,0,0,0,24,79.75V66.25A2.251,2.251,0,0,0,21.75,64ZM19.1,65.5,12,71.107,4.9,65.5Zm1.9,15H3V67.969l9,6.922,9-6.924Z'
              transform='translate(0 -64)'
            />
          </>
        )
    }
  }
  return (
    <StyledSvg
      width={size}
      viewBox={getViewBoxVector()}
      onMouseOver={() => setColored('#313A4D')}
      onMouseOut={() => setColored(color)}
    >
      {getPathVector()}
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  transition: all 0.3s ease;
  path,
  ellipse {
    transition: all 0.3s ease;
  }
`

Social.defaultProps = {
  size: 24,
  color: '#FF7708',
  variant: FACEBOOK
}
