import React from 'react'

export const ConsultationAdd = ({ color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <path
      fill='none'
      stroke={color}
      d='M6.245 13.747h-5a1.25 1.25 0 01-1.25-1.25v-10a1.25 1.25 0 011.25-1.25h11.247a1.25 1.25 0 011.25 1.25v3.749'
      className='a'
      transform='translate(-351.205 -2847.2) translate(351.755 2847.75)'
    ></path>
    <path
      fill='none'
      stroke={color}
      d='M0 4.999h13.745'
      className='b'
      transform='translate(-351.205 -2847.2) translate(351.755 2847.75)'
    ></path>
    <path
      fill='none'
      stroke={color}
      d='M3.749 2.499v-2.5M9.997 2.499v-2.5M13.747 18.745a5 5 0 10-5-5 5 5 0 005 5zM13.746 11.247v5M11.247 13.746h5'
      className='a'
      transform='translate(-351.205 -2847.2) translate(351.755 2847.75)'
    ></path>
  </svg>
)

ConsultationAdd.defaultProps = {
  b: {
    fill: 'none',
    stroke: '#FF7708',
    strokeLinejoin: 'round',
    strokeWidth: '1.1px'
  },
  a: {
    fill: 'none',
    stroke: '#FF7708',
    strokeLinejoin: 'round',
    strokeWidth: '1.1px',
    strokeLinecap: 'round'
  }
}
