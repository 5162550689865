import React from 'react'

export const FilledInfo = ({ backgroundColor, color, size }) => {
  return (
    <svg data-testid='filledInfo-id' width={size} height={size} viewBox='0 0 20 20'>
      <circle fill={backgroundColor} cx='10' cy='10' r='10' />
      <g transform='translate(9 4)'>
        <path
          fill={color}
          d='M52.248,38.505V32.18a1.267,1.267,0,0,0-2.527,0v6.325a1.267,1.267,0,0,0,2.527,0ZM50.984,26.967a1.22,1.22,0,0,0-1.264,1.171h0a1.267,1.267,0,0,0,2.527,0h0A1.22,1.22,0,0,0,50.984,26.967Z'
          transform='translate(-49.721 -26.967)'
        />
      </g>
    </svg>
  )
}

FilledInfo.defaultProps = {
  backgroundColor: '#fff',
  color: '#3C3C3C',
  size: 20
}
