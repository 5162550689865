export const HardwareSecurity = ({ size, color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 48 48'>
    <g clip-path='url(#clip0_35_2926)'>
      <path
        d='M23.9997 2.28198L6.23047 10.1794V22.0256C6.23047 32.9833 13.812 43.2302 23.9997 45.7179C34.1874 43.2302 41.7689 32.9833 41.7689 22.0256V10.1794L23.9997 2.28198ZM23.9997 23.9802H37.8202C36.7738 32.1145 31.3443 39.3604 23.9997 41.631V23.9999H10.1792V12.7461L23.9997 6.60583V23.9802Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_35_2926'>
        <rect
          width='47.3846'
          height='47.3846'
          fill='white'
          transform='translate(0.307373 0.307617)'
        />
      </clipPath>
    </defs>
  </svg>
)
