import React from 'react'

export const Location = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 12.812 16'>
      <path
        fill={color}
        d='M10.934 1.874A6.406 6.406 0 000 6.4a6.694 6.694 0 001.058 3.572 19.027 19.027 0 002.26 2.8 21.333 21.333 0 012.372 2.932.625.625 0 00.528.291h.375a.625.625 0 00.528-.291 21.334 21.334 0 012.372-2.932 19.023 19.023 0 002.26-2.8A6.7 6.7 0 0012.812 6.4a6.365 6.365 0 00-1.878-4.526zm-2.362 10.06a25.76 25.76 0 00-2.165 2.595 25.757 25.757 0 00-2.165-2.595C2.637 10.178 1.252 8.661 1.247 6.4a5.156 5.156 0 0110.312 0c.001 2.261-1.384 3.778-2.988 5.534z'
      ></path>
      <circle
        fill={color}
        cx='1.953'
        cy='1.953'
        r='1.953'
        className='a'
        transform='translate(4.453 4.188)'
      ></circle>
    </svg>
  )
}

Location.defaultProps = {
  color: '#212121',
  size: 18
}
