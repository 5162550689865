import React from 'react'

export const FacebookWithoutBackground = ({ size, color }) => {
  return (
    <svg width={size} height='auto' fill='none' viewBox='0 0 7 13'>
      <path
        fill={color}
        d='M5.093 2.02h1.609c.148 0 .268-.112.268-.252V.253C6.97.113 6.85 0 6.702 0H5.093C3.468 0 2.144 1.246 2.144 2.779v1.768H.268C.12 4.547 0 4.66 0 4.799v1.516c0 .14.12.253.268.253h1.876v5.304c0 .14.12.253.269.253H4.02c.148 0 .268-.114.268-.253V6.568h1.876c.116 0 .218-.07.255-.173l.536-1.516a.241.241 0 00-.037-.228.275.275 0 00-.217-.104H4.289V2.779c0-.418.36-.758.804-.758z'
      />
    </svg>
  )
}
FacebookWithoutBackground.defaultProps = {
  color: '#0A142F',
  size: 7
}
