export const Help = ({ color, size }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 20.005 20.005'>
      <path
        fill={color}
        d='M10,0A10,10,0,1,0,20,10,10.013,10.013,0,0,0,10,0ZM9.586,16.671a1.25,1.25,0,1,1,1.25-1.25A1.252,1.252,0,0,1,9.586,16.671Zm1.25-5.084V12.92a.417.417,0,0,1-.417.417H8.752a.417.417,0,0,1-.417-.417V10.836A1.669,1.669,0,0,1,10,9.169,1.667,1.667,0,1,0,8.335,7.5v.417a.417.417,0,0,1-.417.417H6.252a.417.417,0,0,1-.417-.417V7.5a4.168,4.168,0,1,1,5,4.085Z'
      />
    </svg>
  )
}
Help.defaultProps = {
  color: '#bdc3c7',
  size: '20px'
}
