export const List = ({ color, ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22.5px'
      height='20px'
      viewBox='0 0 22.5 20'
      {...rest}
    >
      <path
        class='a'
        fill={color}
        d='M22.209,6.536,20.964,5.291a1,1,0,0,0-1.407,0L18.493,6.356l2.652,2.652,1.064-1.064A.994.994,0,0,0,22.209,6.536Zm-9.355,5.417a2.5,2.5,0,0,0-.635,1.059L11.624,15h-.967a.439.439,0,0,1-.4-.254,1.754,1.754,0,0,0-2.578-.554l-.542-1.626a.938.938,0,0,0-1.78,0L4.648,14.7a.434.434,0,0,1-.414.3H3.75a.625.625,0,0,0,0,1.25h.485a1.685,1.685,0,0,0,1.6-1.154l.415-1.246.657,1.972a.625.625,0,0,0,.549.425l.044,0a.625.625,0,0,0,.559-.346l.3-.6a.426.426,0,0,1,.392-.242.432.432,0,0,1,.4.255,1.68,1.68,0,0,0,1.509.931h2.469v1.875H1.875V1.879H8.123V5.94a.935.935,0,0,0,.938.937h4.064V9.924L15,8.066V5.155a1.885,1.885,0,0,0-.554-1.328L11.17.551A1.875,1.875,0,0,0,9.846,0H1.875A1.881,1.881,0,0,0,0,1.879V18.125A1.875,1.875,0,0,0,1.875,20H13.122A1.878,1.878,0,0,0,15,18.125V15.188l5.26-5.3L17.609,7.24ZM10,2.031,12.97,5H10V2.031Z'
      />
    </svg>
  )
}

List.defaultProps = {
  color: '#bdc3c7'
}
