import React from 'react'

export const GlobeArrow = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 19.059 19.133'>
      <g fill='none' stroke={color}>
        <path
          d='M10.163 17.71a9.125 9.125 0 01-1.255.086 8.9 8.9 0 118.8-7.634'
          transform='translate(.679 .723)'
        ></path>
        <path
          d='M6.76 17.536c-1.2-1.764-2.008-4.97-2.008-8.633S5.552 2.035 6.76.27M.029 8.311h8.28M12.46 8.311h5.317M1.789 3.568h14.229M1.035 13.054h6.681M11.045.271a15.231 15.231 0 011.976 7.4q.017.323.025.643M9.704 10.363l1.706 5.684a.527.527 0 00.879.222l.869-.87 2.241 2.242a.53.53 0 00.747 0l1.495-1.495a.528.528 0 000-.747l-2.244-2.241.87-.87a.528.528 0 00-.221-.879l-5.685-1.706a.528.528 0 00-.658.659z'
          transform='translate(.679 .723)'
        ></path>
      </g>
    </svg>
  )
}

GlobeArrow.defaultProps = {
  color: '#FF7708',
  size: 18
}
