import React from 'react'
import styled from 'styled-components'
import { layout } from 'styled-system'

const TOP = 'top'
const DOWN = 'down'
const RIGHT = 'right'
const LEFT = 'left'

export const Chevron = ({ size, color, variant, ...props }) => {
  const rotateChevron = () => {
    switch (variant) {
      case TOP:
      default:
        return 0
      case DOWN:
        return 180
      case RIGHT:
        return -90
      case LEFT:
        return 90
    }
  }
  return (
    <StyledSvg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      transform={`rotate(${rotateChevron()} 0 0)`}
      {...props}
    >
      <path
        d='M18,16c-0.3,0-0.5-0.1-0.7-0.3L12,10.4l-5.3,5.3c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4l6-6c0.4-0.4,1-0.4,1.4,0
      l6,6c0.4,0.4,0.4,1,0,1.4C18.5,15.9,18.3,16,18,16z'
        fill={color}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  transition: transform 0.3s ease;
  ${layout}
`

Chevron.defaultProps = {
  size: 24,
  color: '#FF7708',
  variant: TOP
}
