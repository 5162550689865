import React from 'react'

export const Twitter = ({ size, color }) => (
  <svg width={size} height={size} viewBox='0 0 14.933 14.933'>
    <path
      fill={color}
      d='M13.066,0H1.867A1.867,1.867,0,0,0,0,1.867v11.2a1.867,1.867,0,0,0,1.867,1.867h11.2a1.867,1.867,0,0,0,1.867-1.867V1.867A1.867,1.867,0,0,0,13.066,0ZM11.177,5.563c0,.082.005.165.005.248A5.446,5.446,0,0,1,2.8,10.4a3.894,3.894,0,0,0,.457.027,3.842,3.842,0,0,0,2.378-.82,1.917,1.917,0,0,1-1.789-1.33,1.912,1.912,0,0,0,.865-.033A1.916,1.916,0,0,1,3.175,6.366c0-.008,0-.016,0-.024a1.9,1.9,0,0,0,.867.24,1.916,1.916,0,0,1-.593-2.556,5.436,5.436,0,0,0,3.947,2,1.916,1.916,0,0,1,3.263-1.746,3.838,3.838,0,0,0,1.216-.465,1.921,1.921,0,0,1-.842,1.059,3.822,3.822,0,0,0,1.1-.3A3.9,3.9,0,0,1,11.177,5.563Z'
    />
  </svg>
)

Twitter.defaultProps = {
  color: '#7F8C8D',
  size: 20
}
