import React from 'react'

export const TwitterWithoutBackground = ({ size, color }) => {
  return (
    <svg width={size} height='auto' viewBox='0 0 13 11'>
      <path
        fill={color}
        d='M12.22 1.265a.262.262 0 00-.32-.059 2.28 2.28 0 01-.34.127c.187-.257.338-.545.405-.804a.279.279 0 00-.105-.295.262.262 0 00-.306 0c-.145.103-.844.416-1.286.517C9.27-.15 8.108-.242 6.9.483c-.982.59-1.196 1.79-1.154 2.52C3.497 2.78 2.1 1.56 1.313.55a.27.27 0 00-.227-.103.268.268 0 00-.211.134 2.728 2.728 0 00-.298 2.065c.098.384.268.724.465.998a1.45 1.45 0 01-.272-.179.262.262 0 00-.283-.035.276.276 0 00-.153.248c0 1.211.737 2.02 1.424 2.441a1.948 1.948 0 01-.344-.076c-.1-.03-.21 0-.279.082a.28.28 0 00-.042.294 2.937 2.937 0 002.024 1.703c-.791.478-1.85.712-2.819.597a.265.265 0 00-.285.188c-.04.124.012.26.123.324 1.47.85 2.8 1.153 3.949 1.153 1.672 0 2.962-.642 3.748-1.18 2.117-1.445 3.437-4.04 3.253-6.362.34-.258.847-.734 1.162-1.245a.278.278 0 00-.028-.331z'
      />
    </svg>
  )
}
TwitterWithoutBackground.defaultProps = {
  color: '#0A142F',
  size: 52
}
