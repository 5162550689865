import React from 'react'

export const ContentCopy = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 20 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.5 0.672363H2.5C1.4 0.672363 0.5 1.57236 0.5 2.67236V15.6724C0.5 16.2224 0.95 16.6724 1.5 16.6724C2.05 16.6724 2.5 16.2224 2.5 15.6724V3.67236C2.5 3.12236 2.95 2.67236 3.5 2.67236H13.5C14.05 2.67236 14.5 2.22236 14.5 1.67236C14.5 1.12236 14.05 0.672363 13.5 0.672363ZM17.5 4.67236H6.5C5.4 4.67236 4.5 5.57236 4.5 6.67236V20.6724C4.5 21.7724 5.4 22.6724 6.5 22.6724H17.5C18.6 22.6724 19.5 21.7724 19.5 20.6724V6.67236C19.5 5.57236 18.6 4.67236 17.5 4.67236ZM16.5 20.6724H7.5C6.95 20.6724 6.5 20.2224 6.5 19.6724V7.67236C6.5 7.12236 6.95 6.67236 7.5 6.67236H16.5C17.05 6.67236 17.5 7.12236 17.5 7.67236V19.6724C17.5 20.2224 17.05 20.6724 16.5 20.6724Z'
      fill={color}
    />
  </svg>
)

ContentCopy.defaultProps = {
  size: '20px',
  color: '#313A4Ds'
}
