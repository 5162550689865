export const Profile = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 15.82 20.8'>
      <path
        fill={color}
        stroke='none'
        d='M108.723,10.172c2.313,0,4.187-2.277,4.187-5.086S112.295,0,108.723,0s-4.188,2.277-4.188,5.086S106.41,10.172,108.723,10.172Z'
        transform='translate(-100.812)'
      />
      <path
        stroke='none'
        fill={color}
        d='M41.9,300.46c0-.171,0-.048,0,0Z'
        transform='translate(-41.895 -282.521)'
      />
      <path
        stroke='none'
        fill={color}
        d='M308.085,301.642c0-.047,0-.325,0,0Z'
        transform='translate(-292.266 -283.569)'
      />
      <path
        stroke='none'
        fill={color}
        d='M57.717,184.91c-.078-4.893-.717-6.288-5.607-7.17a3.436,3.436,0,0,1-4.586,0c-4.837.873-5.515,2.247-5.6,7.011-.007.389-.011.41-.012.364,0,.085,0,.241,0,.514,0,0,1.164,2.347,7.909,2.347s7.909-2.347,7.909-2.347c0-.175,0-.3,0-.38A3.143,3.143,0,0,1,57.717,184.91Z'
        transform='translate(-41.907 -167.177)'
      />
    </svg>
  )
}
Profile.defaultProps = {
  color: '#bdc3c7',
  size: '20px'
}
