import styled from 'styled-components'
import { layout } from 'styled-system'

const TOP = 'top'
const DOWN = 'down'
const RIGHT = 'right'
const LEFT = 'left'

export const ThinChevron = ({ size, color, variant, ...props }) => {
  const rotateChevron = () => {
    switch (variant) {
      case LEFT:
      default:
        return 0
      case RIGHT:
        return 180
      case DOWN:
        return 90
      case TOP:
        return -90
    }
  }
  return (
    <StyledSvg
      width={size}
      height={size / 2}
      viewBox='2 0 2 13'
      transform={`rotate(${rotateChevron()} 0 0)`}
      {...props}
    >
      <path
        d='M.411 5.006L5.269.138A.469.469 0 015.933.8L1.074 5.669a.469.469 0 000 .663L5.933 11.2a.469.469 0 11-.664.662L.411 6.994a1.408 1.408 0 010-1.988z'
        fill={color}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  transition: transform 0.3s ease;
  ${layout}
`

ThinChevron.defaultProps = {
  size: 24,
  color: '#FF7708',
  variant: TOP
}
