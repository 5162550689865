export const Patients = ({ color }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='27px' height='19px' viewBox='0 0 27.139 19'>
      <path
        class='a'
        fill={color}
        d='M23.071,40.143a3.393,3.393,0,1,0-3.393-3.393A3.392,3.392,0,0,0,23.071,40.143Zm0-4.75a1.357,1.357,0,1,1-1.357,1.357A1.361,1.361,0,0,1,23.071,35.393Zm-19,4.75A3.393,3.393,0,1,0,.679,36.75,3.392,3.392,0,0,0,4.071,40.143Zm0-4.75A1.357,1.357,0,1,1,2.714,36.75,1.361,1.361,0,0,1,4.071,35.393Zm16.812,8.944a4.835,4.835,0,0,0-5.42-1.769,6.466,6.466,0,0,1-3.792,0,4.828,4.828,0,0,0-5.42,1.769,4.86,4.86,0,0,0-.831,2.727v1.9A2.036,2.036,0,0,0,7.456,51H19.67a2.036,2.036,0,0,0,2.036-2.036v-1.9a4.788,4.788,0,0,0-.823-2.727Zm-1.2,4.627H7.464v-1.9a2.858,2.858,0,0,1,2.774-2.85,8.527,8.527,0,0,0,6.667,0,2.855,2.855,0,0,1,2.774,2.85Zm3.9-7.464H22.563a6.312,6.312,0,0,0-1.985.606,11.151,11.151,0,0,1,1.459,1.535,1.546,1.546,0,0,1,.522-.106h1.018A1.531,1.531,0,0,1,25.1,45.062a1.018,1.018,0,1,0,2.036,0A3.561,3.561,0,0,0,23.58,41.5Zm-10.009,0a4.75,4.75,0,1,0-4.75-4.75A4.748,4.748,0,0,0,13.571,41.5Zm0-7.464a2.714,2.714,0,1,1-2.714,2.714A2.717,2.717,0,0,1,13.571,34.036ZM6.565,42.106A3.55,3.55,0,0,0,4.58,41.5H3.563A3.568,3.568,0,0,0,0,45.062a1.018,1.018,0,0,0,2.036,0,1.531,1.531,0,0,1,1.527-1.527H4.58a1.5,1.5,0,0,1,.522.106A6.292,6.292,0,0,1,6.565,42.106Z'
        transform='translate(0 -32)'
      />
    </svg>
  )
}
Patients.defaultProps = {
  color: '#bdc3c7'
}
