import React from 'react'
import styled from 'styled-components'
import { layout } from 'styled-system'

const TOP = 'top'
const DOWN = 'down'
const RIGHT = 'right'
const LEFT = 'left'

export const ThinArrow = ({ size, color, variant, ...props }) => {
  const rotateChevron = () => {
    switch (variant) {
      case TOP:
      default:
        return 180
      case DOWN:
        return 0
      case RIGHT:
        return -90
      case LEFT:
        return 90
    }
  }
  return (
    <StyledSvg
      width={size}
      height={size}
      viewBox='0 0 12 29'
      fill='none'
      transform={`rotate(${rotateChevron()} 0 0)`}
      {...props}
    >
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M6.273 1.5v25.212M11 22.773L6.273 27.5l-4.727-4.727'
      ></path>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  transition: transform 0.3s ease;
  ${layout}
`

ThinArrow.defaultProps = {
  size: 25,
  color: '#FF7708',
  variant: TOP
}
